import trackEvent from '../../helpers/gtm/eventPush';

const trackBroadenSearch = () => {
  const broadenResultsLinks = document.querySelectorAll('.broaden__link');

  if (broadenResultsLinks) {
    // Track on show
    if (broadenResultsLinks.length > 0) {
      trackEvent({
        category: 'narrow',
        action: 'broaden_search_show',
        label: '',
      });
    }

    // Track on click
    broadenResultsLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        trackEvent({
          category: 'narrow',
          action: 'broaden_search',
          label: link.innerText,
        });
      });
    });
  }
};

export default trackBroadenSearch;
