import React from 'react';
import CheckboxButton from '../inputs/checkbox';
import RadioButton from '../inputs/radio';

const RoomAmenities = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="room-amenities-title"
  >
    <div id="room-amenities-title" className="form-group__title">
      <span>
        {props.lookingForARoom ? 'Room amenities' : 'Required room amenities'}
      </span>
    </div>
    <div className="search-filter__radio-wrapper">
      <RadioButton
        name={'room_types'}
        id={'anyRoomSize'}
        value={''}
        checked={props.data.room_types === ''}
        handleChange={(e) => props.updateInput('roomAmenities', e.target)}
        labelText={'Any room size'}
      />
      <RadioButton
        name={'room_types'}
        id={'doubleRoom'}
        value={'double'}
        checked={props.data.room_types === 'double'}
        handleChange={(e) => props.updateInput('roomAmenities', e.target)}
        labelText={props.country == 'US' ? 'Large room' : 'Double room'}
      />
      <RadioButton
        name={'room_types'}
        id={'singleRoom'}
        value={'single'}
        checked={props.data.room_types === 'single'}
        handleChange={(e) => props.updateInput('roomAmenities', e.target)}
        labelText={props.country == 'US' ? 'Small room' : 'Single room'}
      />
    </div>
    {props.lookingForARoom ? (
      <div>
        <hr className="search-filter__separator" />
        <CheckboxButton
          onChange={(e) => props.updateInput('roomAmenities', e.target)}
          name={'ensuite'}
          id={'enSuite'}
          value={'Y'}
          check={props.data.enSuite}
          labelText={props.country == 'US' ? 'Private Bathroom' : 'En-suite'}
        />
      </div>
    ) : null}
    <div className="search-filter__radio-wrapper">
      <hr className="search-filter__separator" />
      <RadioButton
        name={'furnished'}
        id={'furnishedUnfurnished'}
        value={''}
        checked={props.data.furnished === ''}
        handleChange={(e) => props.updateInput('roomAmenities', e.target)}
        labelText={'Furnished or Unfurnished'}
      />
      <RadioButton
        name={'furnished'}
        id={'furnished'}
        value={'Y'}
        checked={props.data.furnished === 'Y'}
        handleChange={(e) => props.updateInput('roomAmenities', e.target)}
        labelText={'Furnished'}
      />
      <RadioButton
        name={'furnished'}
        id={'unfurnished'}
        value={'N'}
        checked={props.data.furnished === 'N'}
        handleChange={(e) => props.updateInput('roomAmenities', e.target)}
        labelText={'Unfurnished'}
      />
    </div>
  </section>
);

export default RoomAmenities;
