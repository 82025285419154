import React from 'react';
import NumberInput from '../../inputs/number';

const RentRange = (props) => (
  <div id="rentRange">
    <label className="sr-only" htmlFor="minRent">
      Minimum rent
    </label>
    <NumberInput
      name={'min_rent'}
      id={'minRent'}
      placeholder={'min'}
      min={'0'}
      max={'99999'}
      size={'5'}
      onChange={(e) => props.updateRent('rent', e.target)}
      value={props.minRent}
      styles={{ minWidth: '70px' }}
    />
    <span className="form-group__text">to</span>
    <label className="sr-only" htmlFor="maxRent">
      Maximum rent
    </label>
    <NumberInput
      name={'max_rent'}
      id={'maxRent'}
      placeholder={'max'}
      min={'0'}
      max={'99999'}
      size={'5'}
      onChange={(e) => props.updateRent('rent', e.target)}
      value={props.maxRent}
      styles={{ minWidth: '70px' }}
    />
  </div>
);

export default RentRange;
