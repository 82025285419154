import React from 'react';
import CheckboxButton from '../inputs/checkbox';

const Miscellaneous = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="miscellaneous-title"
  >
    <div id="miscellaneous-title" className="form-group__title">
      <span>Miscellaneous</span>
    </div>
    <div className="search-filter__radio-wrapper">
      <CheckboxButton
        onChange={(e) => props.updateInput('miscellaneous', e.target)}
        name={'photoadsonly'}
        id={'photoAdsOnly'}
        value={'Y'}
        check={props.data.photoAdsOnly}
        labelText={'Photo ads only'}
      />
      <CheckboxButton
        onChange={(e) => props.updateInput('miscellaneous', e.target)}
        name={'free_to_contact'}
        id={'freeToContact'}
        value={'Y'}
        check={props.data.freeToContact}
        labelText={'Free to contact'}
      />
      { props.lookingForARoom ? (
        <CheckboxButton
          onChange={(e) => props.updateInput('miscellaneous', e.target)}
          name={'no_deposit'}
          id={'noDeposit'}
          value={'Y'}
          check={props.data.noDeposit}
          labelText={'No / low deposit'}
        />
      ) : null }
    </div>
  </section>
);

export default Miscellaneous;
