export const toggleCheckbox = (filterSection, key) => prevState => ({
  filters: {
    ...prevState.filters,
    [filterSection]: {
      ...prevState.filters[filterSection],
      [key]: !prevState.filters[filterSection][key],
    },
  },
});

export const toggleRadioOrSelect = (filterSection, key, value) => prevState => ({
  filters: {
    ...prevState.filters,
    [filterSection]: {
      ...prevState.filters[filterSection],
      [key]: value,
    },
  },
});

export const compareCurrentToInitial = (
  key,
  stateValue,
  initialValue,
  updateFunction,
) => {
  if (stateValue !== initialValue) {
    return updateFunction(key, true);
  }
  return updateFunction(key, false);
};
