import React from 'react';
import RadioButton from '../inputs/radio';
import DateOrder from './move_in_components/date_order';

const getDay = () => {
  const today = new Date();
  let day = today.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  return day;
};

const getMonth = () => {
  const today = new Date();
  let month = today.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  return month;
};

const getYear = () => {
  const today = new Date();
  const year = today.getFullYear();
  return year;
};

const MoveIn = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="move-in-title"
  >
    <div id="move-in-title" className="form-group__title">
      <span>To move in</span>
    </div>
    <div>
      <RadioButton
        name={'available_search'}
        id={'anytime'}
        value={'N'}
        checked={props.data.available_search === ''}
        handleChange={(e) => props.updateInput('moveIn', e.target)}
        labelText={'Anytime'}
      />
      <RadioButton
        name={'available_search'}
        id={'selected_date'}
        handleChange={(e) => props.updateInput('moveIn', e.target)}
        value={'Y'}
        checked={props.data.available_search == 'Y'}
        labelText={
          <>
            <span className="sr-only">Move in on date</span>
            <DateOrder
              country={props.country}
              dayAvailable={props.data.fromDate.day || getDay()}
              monthAvailable={props.data.fromDate.mon || getMonth()}
              yearAvailable={props.data.fromDate.year || getYear()}
              updateDate={props.updateDate}
            />
          </>
        }
      />
    </div>
  </section>
);

export default MoveIn;
