import trackEvent from '../../helpers/gtm/eventPush';

const arrayID = [
  'counties_within_state',
  'cities_within_posttown',
  'postcodes_in_country',
  'neighbourhoods_in_country',
  'campus_suggestions',
  'nearby_areas_lbi',
  'neighbourhoods_within_postcodes_lbi',
  'areas_within_posttown_lbi',
  'us_metros_browse',
];

function trackArea(array) {
  const label = [];
  array.forEach((value) => {
    const area = document.querySelector(`#${value}`);
    if (area) {
      label.push(value);
    }
  });
  if (label.length > 0) {
    trackEvent({
      category: 'narrow',
      action: 'srp_related_areas_shown',
      label: `${label.join('|')}`,
    });
  }
}
trackArea(arrayID);
