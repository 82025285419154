import React from 'react';
import SelectInput from '../inputs/select';

const LengthOfStay = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="length-of-stay-title"
  >
    <div>
      <div id="length-of-stay-title" className="form-group__title">
        <span>Length of stay</span>
      </div>
      <div className="search-filters__length-of-stay">
        <label htmlFor="minTerm">Minimum:</label>
        <SelectInput
          name={'min_term'}
          id={'minTerm'}
          options={min}
          onChange={(e) => props.updateSelect('lengthOfStay', e.target)}
          selected={props.data.minTerm}
        />
      </div>
      <div className="search-filters__length-of-stay">
        <label htmlFor="maxTerm">Maximum:</label>
        <SelectInput
          name={'max_term'}
          id={'maxTerm'}
          options={max}
          onChange={(e) => props.updateSelect('lengthOfStay', e.target)}
          selected={props.data.maxTerm}
        />
      </div>
    </div>
  </section>
);

const min = [
  { value: '0', text: 'Any' },
  { value: '1', text: '1 Month' },
  { value: '2', text: '2 Months' },
  { value: '3', text: '3 Months' },
  { value: '4', text: '4 Months' },
  { value: '5', text: '5 Months' },
  { value: '6', text: '6 Months' },
  { value: '7', text: '7 Months' },
  { value: '8', text: '8 Months' },
  { value: '9', text: '9 Months' },
  { value: '10', text: '10 Months' },
  { value: '11', text: '11 Months' },
  { value: '12', text: '1 Year' },
  { value: '15', text: '1 Year 3 Months' },
  { value: '18', text: '1 Year 6 Months' },
  { value: '21', text: '1 Year 9 Months' },
  { value: '24', text: '2 Years' },
];

const max = [
  { value: '0', text: 'Any' },
  { value: '1', text: '1 Month' },
  { value: '2', text: '2 Months' },
  { value: '3', text: '3 Months' },
  { value: '4', text: '4 Months' },
  { value: '5', text: '5 Months' },
  { value: '6', text: '6 Months' },
  { value: '7', text: '7 Months' },
  { value: '8', text: '8 Months' },
  { value: '9', text: '9 Months' },
  { value: '10', text: '10 Months' },
  { value: '11', text: '11 Months' },
  { value: '12', text: '1 Year' },
  { value: '15', text: '1 Year 3 Months' },
  { value: '18', text: '1 Year 6 Months' },
  { value: '21', text: '1 Year 9 Months' },
  { value: '24', text: '2 Years' },
  { value: '36', text: '3 Years' },
];

export default LengthOfStay;
