import React from 'react';
import SelectInput from '../../inputs/select';

const days = [
  { value: '01', text: '01' },
  { value: '02', text: '02' },
  { value: '03', text: '03' },
  { value: '04', text: '04' },
  { value: '05', text: '05' },
  { value: '06', text: '06' },
  { value: '07', text: '07' },
  { value: '08', text: '08' },
  { value: '09', text: '09' },
  { value: '10', text: '10' },
  { value: '11', text: '11' },
  { value: '12', text: '12' },
  { value: '13', text: '13' },
  { value: '14', text: '14' },
  { value: '15', text: '15' },
  { value: '16', text: '16' },
  { value: '17', text: '17' },
  { value: '18', text: '18' },
  { value: '19', text: '19' },
  { value: '20', text: '20' },
  { value: '21', text: '21' },
  { value: '22', text: '22' },
  { value: '23', text: '23' },
  { value: '24', text: '24' },
  { value: '25', text: '25' },
  { value: '26', text: '26' },
  { value: '27', text: '27' },
  { value: '28', text: '28' },
  { value: '29', text: '29' },
  { value: '30', text: '30' },
  { value: '31', text: '31' },
];

const months = [
  { value: '01', text: '01' },
  { value: '02', text: '02' },
  { value: '03', text: '03' },
  { value: '04', text: '04' },
  { value: '05', text: '05' },
  { value: '06', text: '06' },
  { value: '07', text: '07' },
  { value: '08', text: '08' },
  { value: '09', text: '09' },
  { value: '10', text: '10' },
  { value: '11', text: '11' },
  { value: '12', text: '12' },
];

const currentYear = new Date().getFullYear();
const years = [0, 1, 2].map((increment) => {
  const year = (currentYear + increment).toString();
  return { value: year, text: year };
});

const dateOrder = (props) =>
  props.country == 'GB' ? (
    <div className="form-group__select">
      <label className="sr-only" htmlFor="day">
        Day
      </label>
      <SelectInput
        name={'day_avail'}
        id={'day'}
        options={days}
        onChange={(e) => props.updateDate(e)}
        selected={props.dayAvailable}
      />
      <label className="sr-only" htmlFor="mon">
        Month
      </label>
      <SelectInput
        name={'mon_avail'}
        id={'mon'}
        options={months}
        onChange={(e) => props.updateDate(e)}
        selected={props.monthAvailable}
      />
      <label className="sr-only" htmlFor="year">
        Year
      </label>
      <SelectInput
        name={'year_avail'}
        id={'year'}
        options={years}
        onChange={(e) => props.updateDate(e)}
        selected={props.yearAvailable}
      />
    </div>
  ) : (
    <div className="form-group__select">
      <label className="sr-only" htmlFor="day">
        Day
      </label>
      <SelectInput
        name={'mon_avail'}
        id={'mon'}
        options={months}
        onChange={(e) => props.updateDate(e)}
        selected={props.monthAvailable}
      />
      <label className="sr-only" htmlFor="mon">
        Month
      </label>
      <SelectInput
        name={'day_avail'}
        id={'day'}
        options={days}
        onChange={(e) => props.updateDate(e)}
        selected={props.dayAvailable}
      />
      <label className="sr-only" htmlFor="year">
        Year
      </label>
      <SelectInput
        name={'year_avail'}
        id={'year'}
        options={years}
        onChange={(e) => props.updateDate(e)}
        selected={props.yearAvailable}
      />
    </div>
  );

export default dateOrder;
