import '../../../../sass/components/list/srp_promo.scss';
import {
  trackPromoView,
  trackPromoClick,
} from '../../../components/tracking/srp_promos';

export default function trackPromo() {
  const promo = document.getElementById('srp-promo');
  if (promo) {
    const results = document.querySelectorAll('.listing-results > li');
    const index = Array.prototype.indexOf.call(results, promo);
    const position = index + 1;
    const link = promo.children[0];
    const this_page = window._sr.page.name;
    const { promoId: promo_type, randomised: is_randomised } = promo.dataset;
    link.addEventListener('click', () => {
      trackPromoClick({
        action: 'srp_promo_panel_click',
        promo_type,
        position,
        this_page,
        target: link.href,
        is_randomised,
      });
    });
    trackPromoView({
      action: 'srp_promo_panel_view',
      promo_type,
      position,
      this_page,
      target: link.href,
      is_randomised,
    });
  }
}
