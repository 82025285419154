import React from 'react';

const CheckboxButton = props => (
  <div className="form-group__checkbox">
    <input
      onChange={e => props.onChange(e)}
      type="checkbox"
      name={props.name}
      id={props.id}
      value={props.value}
      checked={props.check}
    />
    <label htmlFor={props.id} style={props.labelStyle}>
      {props.labelText}
    </label>
  </div>
);

export default CheckboxButton;
