/* eslint-disable no-shadow */
import React from 'react';
import ReactDOM from 'react-dom';
import SearchTools from './index';
import currentFilterData from '../search_filters/helpers/transform_filter_data';
import defaultFilters from '../search_filters/helpers/reset_filter_state';

const updateSortBy = (value) => {
  const params = new URLSearchParams(window.location.search);
  if (value) {
    params.set('sort_by', value);
  } else {
    params.delete('sort_by');
  }
  if (params.toString()) {
    window.location.search = params;
  } else {
    window.location.href = window.location.origin + window.location.pathname;
  }
};

const closeFilterOverlay = (event) => {
  const filterWrapper = document.querySelector('#searchFilterWrapper');
  if (event && event.target === filterWrapper) {
    filterWrapper.classList.remove('form-visible');
  }
};

const showFilters = () => {
  if (!document.querySelector('#searchFilterWrapper')) {
    const filterElement = document.querySelector('.search-filters');
    const filters = document.querySelector('.search-filter-form');
    const newFilterWrapper = document.createElement('div');
    newFilterWrapper.id = 'searchFilterWrapper';
    newFilterWrapper.appendChild(filters);
    newFilterWrapper.addEventListener('click', closeFilterOverlay);
    if (filterElement) {
      filterElement.appendChild(newFilterWrapper);
    }
  }
  document.querySelector('#searchFilterWrapper').classList.add('form-visible');
  window.searchFilters.showFilters();
  // we want to focus on the first labeled form element.
  const filterFocus = document.querySelector(
    '.search-filter-form .filters label',
  );
  if (filterFocus) {
    filterFocus.focus();
  }
};

const showHideFilters = (event) => {
  event.preventDefault();
  if (document.querySelector('.form-visible')) {
    return closeFilterOverlay();
  }
  return showFilters();
};

const narrowSearchLink =
  document.querySelector('.msg__narrow-search-link') ||
  document.querySelector('.message-box__narrow-search-link');

if (narrowSearchLink) {
  narrowSearchLink.addEventListener('click', (event) => {
    event.preventDefault();
    showFilters();
  });
}

const mountSearchTool = (mountElement) => {
  const {
    searchType,
    searchMapUrl,
    searchSaveUrl,
    searchSortBy,
    searchResultTotal,
    searchAdvOptions,
  } = mountElement.dataset;

  if (searchType === 'wanted') {
    defaultFilters.propertyType = '';
    currentFilterData.propertyType = '';
  }

  ReactDOM.render(
    <SearchTools
      searchType={searchType}
      mapUrl={searchMapUrl}
      saveUrl={searchSaveUrl}
      sortBy={searchSortBy}
      resultTotal={searchResultTotal}
      showHideFilters={showHideFilters}
      updateSortBy={updateSortBy}
      numberOfFiltersUsed={searchAdvOptions}
    />,
    mountElement,
  );
};

export default mountSearchTool;
