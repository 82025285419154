import { fetchData } from '../../helpers/base_data_fetcher';
import caseStudyBanner from './case_study_banner';

const results = document.querySelectorAll('.listing-result');
const caseStudyBannerMountElement = document.querySelector(
  '#case_study_banner',
);
function caseStudyBannerPlacement() {
  if (results.length > 2) {
    const thirdElement = results[2];
    thirdElement.before(caseStudyBannerMountElement);
    caseStudyBannerMountElement.classList.add('case-study-banner--loaded');
  }
}

if (caseStudyBannerMountElement) {
  Promise.all([
    fetchData('site'),
    fetchData('page.flatshare_type'),
    fetchData('config.features'),
  ]).then(([site, flatshare_type, features]) => {
    const { country } = site;

    if (country == 'GB' && features.case_study_banner) {
      caseStudyBanner({
        mount_element: caseStudyBannerMountElement,
        flatshare_type,
        site,
      });

      caseStudyBannerPlacement();
    }
  });
}
