import React from 'react';
import ReactDOM from 'react-dom';
import trackEvent from '../../helpers/gtm/eventPush';
import '../../../sass/components/case_study_banner.scss';

const campaignID = '1g';
const CaseStudyPromo = ({ flatshare_type, isMobile }) => {
  const pollId = '961';
  //  const pollId = flatshare_type == 'offered' ? '960' : '959';
  const mobDeskVersion = isMobile ? 'mobile' : 'desktop';
  // const bannerImg = flatshare_type == 'offered' ? 'flatshare' : 'flatmate';
  return (
    <a
      className="listing-csb__link"
      href={`/flatshare/pollvote.pl?poll_id=${pollId}`}
      onClick={() => trackBanner(campaignID)}
    >
      <img
        alt="Metro want to speak to you"
        className="banner-image"
        src={`/img/spareroom/banners/Metro_banner_${mobDeskVersion}.png?v=2`}
      />
    </a>
  );
};

function trackBanner(id) {
  trackEvent({
    category: 'banners',
    action: 'srp_promo_panel_click',
    label: `${id}|3|${window._sr.page.name}`,
  });
}
function trackBannerView(id) {
  trackEvent({
    category: 'banners',
    action: 'srp_promo_panel_view',
    label: `${id}|3|${window._sr.page.name}`,
    non_interaction: true,
  });
}

const CaseStudyPromoBanner = ({ mount_element, flatshare_type, site }) => {
  if (flatshare_type == 'wanted') {
    ReactDOM.render(
      <CaseStudyPromo flatshare_type={flatshare_type} isMobile={site.mobile} />,
      mount_element,
    );
    trackBannerView(campaignID);
  }
};
export default CaseStudyPromoBanner;
