import Cookies from 'js-cookie';
import regPrompt from '.';

export default function({ includeKnownUsers = false, views = 4 } = {}) {
  const userKnown = Cookies.get('user_id');
  const advertViewCount = Cookies.get('advert_view_count');
  if (!includeKnownUsers && userKnown) {
    return false;
  }
  if (!advertViewCount) {
    return false;
  }
  if (parseInt(advertViewCount, 10) < views) {
    return false;
  }
  // blockAllAds();
  return afterViews();
}

function afterViews() {
  const adsLinks = document.querySelectorAll('.listing-result');
  adsLinks.forEach((adLink) => {
    adLink.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      regPrompt();
    });
  });
}
