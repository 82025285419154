import React from 'react';
import ReactDOM from 'react-dom';
import ColivingTooltipInfoModal from './components/ColivingTooltipInfoModal';
import '../../../sass/components/list/coliving.scss';

export default function colivingInfoModal() {
  const colivingTooltipModal = document.getElementById('colivingTooltipModal');
  if (colivingTooltipModal) {
    ReactDOM.render(
      <ColivingTooltipInfoModal
        locale={colivingTooltipModal.dataset.locale}
       />,
      colivingTooltipModal,
    );  
  }    
}
