import React, { Component } from 'react';
import '../../../../sass/components/forms.scss';
import '../../../../sass/components/list/search_filter.scss';
import LimitToPeopleLooking from './filters/limit_to_people_looking';
import Rent from './filters/rent';
import MoveIn from './filters/move_in';
import LengthOfStay from './filters/length_of_stay';
import DaysAvailable from './filters/days_available';
import PropertyType from './filters/property_type';
import RoomAmenities from './filters/room_amenities';
import NumberOfRooms from './filters/number_of_rooms';
import RoomsFor from './filters/rooms_for';
import ShareWith from './filters/share_with';
import Couples from './filters/couples';
import AgeRange from './filters/age_range';
import HouseholdOptions from './filters/household_options';
import SizeOfHousehold from './filters/size_of_household';
import PropertyPreferences from './filters/property_preferences';
import Miscellaneous from './filters/miscellaneous';
import PostedBy from './filters/posted_by';
import RoomsSuitableForAgesRange from './filters/rooms_suitable_for_ages_range';
import Keywords from './filters/keywords';
import Footer from './footer';
import {
  compareCurrentToInitial,
  toggleCheckbox,
  toggleRadioOrSelect,
} from './helpers/update_inputs';
import resetState from './helpers/reset_filter_state';
import storageAvailable from '../../../helpers/storage_available';

class Filters extends Component {
  state = {
    reset: false,
    filters: this.props.filterData,
    offeredSearch: this.props.searchType === 'offered',
    changed: {},
    cancel: false,
    numOfRoomsVisible: !!this.props.filterData.numberOfRooms.numOfRooms,
    formSubmitting: false,
    originalPostedByValue: this.props.filterData.postedBy.posted_by,
  };

  updateFilterChange = (name, value) => {
    this.setState((prevState) => ({
      changed: {
        ...prevState.changed,
        [name]: value,
      },
    }));
  };

  showFilters = () => {
    // for now this just used to display the cancel button
    this.setState({ cancel: true });
  };

  hideFilters = () => {
    const filterWrapper = document.querySelector('#searchFilterWrapper');
    if (filterWrapper) {
      // should the cancel button also reset the filters?
      // we only have this button on mobile - I assumed it was just meant to close the filters.
      this.setState((prevState) => ({
        cancel: !prevState.cancel,
        filters: this.props.filterData,
        changed: {},
      }));
      const filterElement = document.querySelector('.search-filters');
      const filter = filterWrapper.childNodes;
      filterWrapper.remove();
      if (filterElement) {
        filterElement.appendChild(filter[0]);
      }
    }
  };

  resetFilters = () => {
    this.setState((prevState) => ({
      filters: resetState,
      numOfRoomsVisible: false,
    }));
  };

  validateAges = (minAge, maxAge, minAgeInput, maxAgeInput) => {
    const maxUserAge = 99;
    if (maxAge && minAge && minAge > maxAge) {
      minAgeInput.setCustomValidity(
        'Min age must be less than or equal to max age.',
      );
    } else if (minAge > maxUserAge) {
      minAgeInput.setCustomValidity(`Min age must be ${maxUserAge} or less.`);
    } else if (maxAge > maxUserAge) {
      maxAgeInput.setCustomValidity(`Max age must be ${maxUserAge} or less.`);
    } else {
      minAgeInput.setCustomValidity('');
      maxAgeInput.setCustomValidity('');
    }
    minAgeInput.reportValidity();
    maxAgeInput.reportValidity();
  };

  validateForm = () => {
    this.setState({ formSubmitting: true });
    const filterForm = document.getElementById('filterForm');

    // Age Range Of Current Occupants
    const { minAge, maxAge } = this.state.filters.ageRange;
    const minAgeInput = document.getElementById('minAge');
    const maxAgeInput = document.getElementById('maxAge');
    if (minAgeInput && maxAgeInput) {
      this.validateAges(minAge, maxAge, minAgeInput, maxAgeInput);
    }

    // Rooms Suitable For Ages
    const {
      minSuitableAge,
      maxSuitableAge,
    } = this.state.filters.roomsSuitableForAges;
    const minSuitableAgeInput = document.getElementById('minSuitableAge');
    const maxSuitableAgeInput = document.getElementById('maxSuitableAge');
    if (minSuitableAgeInput && maxSuitableAgeInput) {
      this.validateAges(
        minSuitableAge,
        maxSuitableAge,
        minSuitableAgeInput,
        maxSuitableAgeInput,
      );
    }

    if (!filterForm.checkValidity()) {
      this.setState({ formSubmitting: false });
    }

    if (storageAvailable('localStorage')) {
      const form = document.getElementById('filterForm');
      const formData = new FormData(form);
      const params = new URLSearchParams(formData);
      const filterAppliedValue =
        JSON.stringify(this.state.filters) === JSON.stringify(resetState)
          ? 'filtered_search_reset'
          : `js:${params.toString()}`;
      localStorage.setItem('filter_applied', filterAppliedValue);
    }
  };

  checkInputType = (filterSection, input) => {
    const { type, name, id, value } = input;
    document.getElementById(id).setCustomValidity('');
    if (type === 'checkbox') {
      this.updateCheckbox(filterSection, id);
    } else if (type === 'radio') {
      this.updateRadio(filterSection, name, value);
    } else {
      this.updateTextOrNumber(filterSection, input);
    }
  };

  updateCheckbox = (filterSection, id) => {
    this.setState(toggleCheckbox(filterSection, id), () =>
      compareCurrentToInitial(
        id,
        this.state.filters[filterSection][id],
        this.props.filterData[filterSection][id],
        this.updateFilterChange,
      ),
    );
  };

  updateRadio = (filterSection, name, value) => {
    if (name === 'available_search' && value === 'N') {
      value = '';
    }
    this.setState(toggleRadioOrSelect(filterSection, name, value), () =>
      compareCurrentToInitial(
        name,
        this.state.filters[filterSection][name],
        this.props.filterData[filterSection][name],
        this.updateFilterChange,
      ),
    );
  };

  updateSelect = (filterSection, input) => {
    const { id, value } = input;
    this.setState(toggleRadioOrSelect(filterSection, id, value), () =>
      compareCurrentToInitial(
        id,
        this.state.filters[filterSection][id],
        this.props.filterData[filterSection][id],
        this.updateFilterChange,
      ),
    );
  };

  updateTextOrNumber = (filterSection, input) => {
    const { type, id } = input;
    let { value } = input;

    if (type === 'number') {
      value = Math.floor(value);
    }
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [filterSection]: {
          ...prevState.filters[filterSection],
          [id]: value || null,
        },
      },
    }));
    if (value == this.props.filterData[filterSection][id]) {
      return this.updateFilterChange(id, false);
    }
    return this.updateFilterChange(id, true);
  };

  updateMoveInDate = (event) => {
    const {
      target: { id, value },
    } = event;
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        moveIn: {
          ...prevState.filters.moveIn,
          fromDate: {
            ...prevState.filters.moveIn.fromDate,
            [id]: value,
          },
        },
      },
    }));
    if (value == this.props.filterData.moveIn.fromDate[id]) {
      return this.updateFilterChange(id, false);
    }
    return this.updateFilterChange(id, true);
  };

  toggleNumberOfRooms = () => {
    this.setState((prevState) => ({
      numOfRoomsVisible: !this.state.numOfRoomsVisible,
    }));
    return this.updateFilterChange('numOfRooms', true);
  };

  render() {
    return (
      <div
        className="search-filter filters"
        role="dialog"
        aria-labelledby="filter_modal_label"
        aria-modal="true"
      >
        <section className="search-filter__header">
          <h3 id="filter_modal_label">Filters</h3>
          {this.props.mobile ? (
            <a
              className="search-filter__close-link"
              href="#open_search_filters"
              onClick={this.hideFilters}
              title="close the search filters"
            >
              <i className="far fa-times" aria-hidden="true"></i>
              <span>Close</span>
            </a>
          ) : (
            <div className="search-filter__reset">
              <button
                type="button"
                className="button button--small button--link"
                onClick={() => this.resetFilters()}
              >
                Reset filters
              </button>
            </div>
          )}
        </section>
        {this.props.mobile &&
        !this.state.offeredSearch &&
        this.props.country == 'GB' &&
        this.state.filters.limitToPeopleLooking.maxOtherAreasID ? (
          <LimitToPeopleLooking
            data={this.state.filters.limitToPeopleLooking}
            updateSelect={this.updateSelect}
          />
        ) : null}
        <Rent
          country={this.props.country}
          currency={this.props.currency}
          rent={this.state.filters.rent}
          updateInput={this.checkInputType}
          lookingForARoom={this.state.offeredSearch}
        />
        <MoveIn
          country={this.props.country}
          // yearsAvailable={this.props.yearsAvailable}
          data={this.state.filters.moveIn}
          updateInput={this.checkInputType}
          updateDate={this.updateMoveInDate}
        />
        <LengthOfStay
          data={this.state.filters.lengthOfStay}
          updateSelect={this.updateSelect}
        />
        <DaysAvailable
          data={this.state.filters.daysAvailable}
          country={this.props.country}
          updateInput={this.checkInputType}
          lookingForARoom={this.state.offeredSearch}
        />
        {this.state.offeredSearch ? (
          <PropertyType
            data={this.state.filters.propertyType}
            updateInput={this.checkInputType}
            country={this.props.country}
          />
        ) : null}
        <RoomAmenities
          data={this.state.filters.roomAmenities}
          updateInput={this.checkInputType}
          country={this.props.country}
          lookingForARoom={this.state.offeredSearch}
        />
        <NumberOfRooms
          numberOfRooms={this.state.filters.numberOfRooms.numOfRooms}
          updateSelect={this.updateSelect}
          toggleNumberOfRooms={this.toggleNumberOfRooms}
          numOfRoomsVisible={this.state.numOfRoomsVisible}
        />
        {this.state.offeredSearch ? (
          <RoomsFor
            data={this.state.filters.roomsFor}
            updateInput={this.checkInputType}
          />
        ) : null}
        <ShareWith
          data={this.state.filters.sharingWith}
          updateInput={this.checkInputType}
          lookingForARoom={this.state.offeredSearch}
        />
        {!this.state.offeredSearch ? (
          <Couples
            data={this.state.filters.couples}
            updateInput={this.checkInputType}
          />
        ) : null}
        <AgeRange
          data={this.state.filters.ageRange}
          title={
            this.state.offeredSearch
              ? 'Age range of current occupants'
              : 'Age range'
          }
          updateInput={this.checkInputType}
        />
        <HouseholdOptions
          data={this.state.filters.householdOptions}
          updateInput={this.checkInputType}
          lookingForARoom={this.state.offeredSearch}
        />
        {this.state.offeredSearch ? (
          <SizeOfHousehold
            data={this.state.filters.sizeOfHousehold}
            country={this.props.country}
            updateSelect={this.updateSelect}
          />
        ) : null}
        <PropertyPreferences
          data={this.state.filters.propertyPreferences}
          updateInput={this.checkInputType}
          country={this.props.country}
          lookingForARoom={this.state.offeredSearch}
        />
        {this.state.offeredSearch ? (
          <RoomsSuitableForAgesRange
            data={this.state.filters.roomsSuitableForAges}
            updateInput={this.checkInputType}
          />
        ) : null}
        {this.state.offeredSearch ? (
          <PostedBy
            data={this.state.filters.postedBy}
            updateInput={this.checkInputType}
            originalPostedByValue={this.state.originalPostedByValue}
            localisations={this.props.localisations}
          />
        ) : null}
        <Miscellaneous
          data={this.state.filters.miscellaneous}
          updateInput={this.checkInputType}
          lookingForARoom={this.state.offeredSearch}
        />
        <Keywords
          data={this.state.filters.keywords}
          updateInput={this.checkInputType}
        />
        <Footer
          mobile={this.props.mobile}
          validateForm={this.validateForm}
          resetFilters={this.resetFilters}
          formSubmitting={this.state.formSubmitting}
        />
      </div>
    );
  }
}

export default Filters;
