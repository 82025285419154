import React from 'react';
import SelectInput from '../inputs/select';

const LimitToPeopleLooking = (props) => (
  <section className="search-filter__section">
    <div>
      <label className="form-group__title" htmlFor="maxOtherAreasID">
        <span>Limit to people looking:</span>
      </label>
      <SelectInput
        name={'max_other_areas'}
        id={'maxOtherAreasID'}
        options={maxOtherAreasOptions}
        onChange={(e) => props.updateSelect('limitToPeopleLooking', e.target)}
        selected={props.data.maxOtherAreasID}
      />
    </div>
  </section>
);

const maxOtherAreasOptions = [
  { value: '', text: 'No limits applied' },
  { value: 'zero', text: 'This area only' },
  { value: '1', text: 'This area + 1 other max' },
  { value: '2', text: 'This area + 2 others max' },
  { value: '3', text: 'This area + 3 others max' },
  { value: '4', text: 'This area + 4 others max' },
  { value: '5', text: 'This area + 5 others max' },
];

export default LimitToPeopleLooking;
