import React from 'react';
import RadioButton from '../inputs/radio';

const ShareWith = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="share-with-title"
  >
    <div id="share-with-title" className="form-group__title">
      <span>
        {props.lookingForARoom ? 'Share with' : 'Happy to share with'}
      </span>
    </div>
    <div className="search-filter__radio-wrapper">
      <RadioButton
        name={'share_type'}
        id={'anyOccupation'}
        value={''}
        checked={props.data.share_type === ''}
        handleChange={(e) => props.updateInput('sharingWith', e.target)}
        labelText={'Any occupation'}
      />
      <RadioButton
        name={'share_type'}
        id={'professionalShare'}
        value={'P'}
        checked={props.data.share_type === 'P'}
        handleChange={(e) => props.updateInput('sharingWith', e.target)}
        labelText={'Professionals'}
      />
      <RadioButton
        name={'share_type'}
        id={'studentShare'}
        value={'S'}
        checked={props.data.share_type == 'S'}
        handleChange={(e) => props.updateInput('sharingWith', e.target)}
        labelText={'Student'}
      />
    </div>
    <hr className="search-filter__separator" />
    <div className="search-filter__radio-wrapper">
      <RadioButton
        name={'genderfilter'}
        id={'withAnyGenderShare'}
        value={'none'}
        checked={props.data.genderfilter === 'none' || !props.data.genderfilter}
        handleChange={(e) => props.updateInput('sharingWith', e.target)}
        labelText={'Any Gender'}
      />
      <RadioButton
        name={'genderfilter'}
        id={'femalesOnlyShare'}
        value={'females'}
        checked={props.data.genderfilter === 'females'}
        handleChange={(e) => props.updateInput('sharingWith', e.target)}
        labelText={'Females'}
      />
      <RadioButton
        name={'genderfilter'}
        id={'malesOnlyShare'}
        value={'males'}
        checked={props.data.genderfilter == 'males'}
        handleChange={(e) => props.updateInput('sharingWith', e.target)}
        labelText={'Males'}
      />
      <RadioButton
        name={'genderfilter'}
        id={'mixedHoushold'}
        value={'mixed'}
        checked={props.data.genderfilter === 'mixed'}
        handleChange={(e) => props.updateInput('sharingWith', e.target)}
        labelText={'Mixed Household'}
      />
    </div>
  </section>
);

export default ShareWith;
