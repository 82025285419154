import get from '../../../../helpers/data_layer/get';

const filterData = get('page.search.filters');

const rationalisedFilterData = {
  available_from: filterData.available_from || false,
  available_search: filterData.available_search,
  bills_inc: !!filterData.bills_inc,
  days_of_wk_available: {
    allPeriods: !filterData.days_of_wk_available,
    sevenDaysPerWeek: filterData.days_of_wk_available == '7 days a week',
    monToFriOnly: filterData.days_of_wk_available == 'Mon to Fri only',
    weekendsOnly: filterData.days_of_wk_available == 'Weekends only',
  },
  disabled_access: !!filterData.disabled_access,
  ensuite: !!filterData.ensuite,
  fees_apply: {
    noBrokerFees: filterData.fees_apply == 'N',
  },
  free_to_contact: !!filterData.free_to_contact,
  gayshare: !!filterData.gayshare,
  genderfilter: filterData.genderfilter,
  keyword: filterData.keyword,
  living_room: !!filterData.living_room,
  max_age_req: filterData.max_age_req || false,
  max_beds: filterData.max_beds || false,
  max_other_areas: filterData.max_other_areas
    ? filterData.max_other_areas
    : false,
  max_rent: filterData.max_rent,
  max_suitable_age: filterData.max_suitable_age || false,
  max_term: filterData.max_term ? filterData.max_term : false,
  min_age_req: filterData.min_age_req || false,
  min_beds: filterData.min_beds || false,
  min_rent: filterData.min_rent,
  min_suitable_age: filterData.min_suitable_age || false,
  min_term: filterData.min_term ? filterData.min_term : false,
  no_of_rooms: filterData.no_of_rooms,
  no_deposit: !!filterData.no_deposit,
  parking: !!filterData.parking,
  pets_req: !!filterData.pets_req,
  photoadsonly: !!filterData.photoadsonly,
  posted_by: filterData.posted_by,
  room_types: {
    anyRoomSize: !filterData.room_types,
    doubleRoom: filterData.room_types == 'double',
    singleRoom: filterData.room_types == 'single',
  },
  rooms_for: filterData.rooms_for,
  share_type: filterData.share_type,
  short_lets_considered: !!filterData.short_lets_considered,
  showme_1beds: !!filterData.showme_1beds,
  showme_buddyup_properties: !!filterData.showme_buddyup_properties,
  showme_rooms: !!filterData.showme_rooms,
  smoking: {
    smokingOrNonSmoking: !filterData.smoking,
    smoking: filterData.smoking == 'Y',
    nonSmoking: filterData.smoking == 'N',
  },
  vegetarians: !!filterData.vegetarians,
};

const restructuredFilterData = {
  limitToPeopleLooking: {
    maxOtherAreasID: rationalisedFilterData.max_other_areas || '',
  },
  rent: {
    minRent: rationalisedFilterData.min_rent,
    maxRent: rationalisedFilterData.max_rent,
    per: filterData.per || 'pcm',
    billsIncluded: rationalisedFilterData.bills_inc,
    feesApply: rationalisedFilterData.fees_apply.noBrokerFees,
  },
  moveIn: {
    available_search: rationalisedFilterData.available_search,
    fromDate: {
      day: rationalisedFilterData.available_from
        ? rationalisedFilterData.available_from.slice(8)
        : false,
      mon: rationalisedFilterData.available_from
        ? rationalisedFilterData.available_from.slice(5, 7)
        : false,
      year: rationalisedFilterData.available_from
        ? rationalisedFilterData.available_from.slice(0, 4)
        : false,
    },
  },
  lengthOfStay: {
    minTerm: rationalisedFilterData.min_term || '0',
    maxTerm: rationalisedFilterData.max_term || '0',
  },
  daysAvailable: {
    days_of_wk_available: filterData.days_of_wk_available,
    shortLets: rationalisedFilterData.short_lets_considered,
  },
  propertyType: {
    roomsInShares: rationalisedFilterData.showme_rooms,
    oneBedOrStudio: rationalisedFilterData.showme_1beds,
    wholeProperty: rationalisedFilterData.showme_buddyup_properties,
  },
  roomAmenities: {
    room_types: filterData.room_types,
    enSuite: rationalisedFilterData.ensuite,
    furnished: filterData.furnished,
  },
  numberOfRooms: { numOfRooms: rationalisedFilterData.no_of_rooms },
  roomsFor: { rooms_for: filterData.rooms_for },
  sharingWith: {
    share_type: rationalisedFilterData.share_type,
    genderfilter: rationalisedFilterData.genderfilter,
  },
  couples: { couples: filterData.couples },
  ageRange: {
    minAge: rationalisedFilterData.min_age_req,
    maxAge: rationalisedFilterData.max_age_req,
  },
  householdOptions: {
    lgbtShare: rationalisedFilterData.gayshare,
    vegetarians: rationalisedFilterData.vegetarians,
  },
  sizeOfHousehold: {
    minBeds: rationalisedFilterData.min_beds || '',
    maxBeds: rationalisedFilterData.max_beds || '',
  },
  propertyPreferences: {
    smoking: filterData.smoking,
    petsConsidered: rationalisedFilterData.pets_req,
    sharedLivingRoom: rationalisedFilterData.living_room,
    parkingAvailable: rationalisedFilterData.parking,
    disabledAccess: rationalisedFilterData.disabled_access,
  },
  roomsSuitableForAges: {
    minSuitableAge: rationalisedFilterData.min_suitable_age,
    maxSuitableAge: rationalisedFilterData.max_suitable_age,
  },
  postedBy: {
    posted_by: rationalisedFilterData.posted_by,
  },
  miscellaneous: {
    photoAdsOnly: rationalisedFilterData.photoadsonly,
    freeToContact: rationalisedFilterData.free_to_contact,
    noDeposit: rationalisedFilterData.no_deposit,
  },
  keywords: { keywords: rationalisedFilterData.keyword || '' },
};

export default restructuredFilterData;
