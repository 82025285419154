import React from 'react';
import CheckboxButton from '../inputs/checkbox';

const PropertyType = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="property-type-title"
  >
    <div id="property-type-title" className="form-group__title">
      <span>Property type</span>
    </div>
    <CheckboxButton
      onChange={(e) => props.updateInput('propertyType', e.target)}
      name={'showme_rooms'}
      id={'roomsInShares'}
      value={'Y'}
      check={props.data.roomsInShares}
      labelText={'Rooms in existing shares'}
    />
    <CheckboxButton
      onChange={(e) => props.updateInput('propertyType', e.target)}
      name={'showme_1beds'}
      id={'oneBedOrStudio'}
      value={'Y'}
      check={props.data.oneBedOrStudio}
      labelStyle={{ width: '190px', display: 'inline-flex' }}
      labelText={
        props.country == 'US'
          ? 'Studio / 1 bedroom apartments'
          : 'Studio / 1 bed flats'
      }
    />
    <CheckboxButton
      onChange={(e) => props.updateInput('propertyType', e.target)}
      name={'showme_buddyup_properties'}
      id={'wholeProperty'}
      value={'Y'}
      check={props.data.wholeProperty}
      labelStyle={{ width: '190px', display: 'inline-flex' }}
      labelText={'Whole properties (suitable for sharing)'}
    />
  </section>
);

export default PropertyType;
