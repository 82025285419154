import getActiveExperimentVariant from '../helpers/optimize';
import { fetchData } from '../../helpers/base_data_fetcher';
import {
  trackPromoView,
  trackPromoClick,
} from '../../components/tracking/srp_promos';

const spotlight = document.querySelector('.spotlight');

function showSpotlight() {
  spotlight.classList.add('spotlight--shown');
  const spotlight_id = spotlight.dataset.creative;
  const is_animated = spotlight.classList.contains('spotlight--animated');
  const creative = spotlight_id + (is_animated ? '-animated' : '');
  const this_page = window._sr.page.name;
  const target = spotlight.href;
  trackPromoView({
    category: 'banners',
    action: 'srp_spotlight_panel_view',
    promo_type: creative,
    this_page,
    target,
  });
  spotlight.addEventListener('click', () => {
    trackPromoClick({
      category: 'banners',
      action: 'srp_spotlight_panel_click',
      promo_type: creative,
      this_page,
      target,
    });
  });
}

async function triggerExperiment({ experiment_id, dataLayer }) {
  dataLayer.push({
    event: 'optimize.activate.spotlights',
  });
  try {
    const variant = await getActiveExperimentVariant(experiment_id);
    if (variant != 0) {
      showSpotlight();
    }
  } catch (error) {
    console.log(error.message);
  }
}

function spotlightExperiment() {
  if (spotlight) {
    Promise.all([fetchData('experiments'), fetchData('site')]).then(
      ([experiments, site]) => {
        const experiment_id = site.mobile
          ? experiments.spotlight_mobile
          : experiments.spotlight_desktop;
        triggerExperiment({
          experiment_id,
          dataLayer: window.dataLayer,
        });
      },
    );
  }
}

export default spotlightExperiment;
