const resetState = {
  limitToPeopleLooking: {
    maxOtherAreasID: '',
  },
  rent: {
    minRent: '',
    maxRent: '',
    per: 'pcm',
    billsIncluded: false,
    feesApply: false,
  },
  moveIn: {
    available_search: '',
    fromDate: {
      day: false,
      mon: false,
      year: false,
    },
  },
  lengthOfStay: {
    minTerm: '0',
    maxTerm: '0',
  },
  daysAvailable: {
    days_of_wk_available: '',
    shortLets: false,
  },
  propertyType: {
    roomsInShares: true,
    oneBedOrStudio: true,
    wholeProperty: true,
  },
  roomAmenities: {
    room_types: '',
    enSuite: false,
    furnished: '',
  },
  numberOfRooms: {
    numOfRooms: '',
  },
  roomsFor: {
    rooms_for: '',
  },
  sharingWith: {
    share_type: '',
    genderfilter: '',
  },
  couples: {
    couples: '',
  },
  ageRange: {
    minAge: false,
    maxAge: false,
  },
  householdOptions: {
    lgbtShare: false,
    vegetarians: false,
  },
  sizeOfHousehold: {
    minBeds: '',
    maxBeds: '',
  },
  propertyPreferences: {
    smoking: '',
    petsConsidered: false,
    sharedLivingRoom: false,
    parkingAvailable: false,
    disabledAccess: false,
  },
  roomsSuitableForAges: {
    minSuitableAge: false,
    maxSuitableAge: false,
  },
  postedBy: {
    posted_by: '',
  },
  miscellaneous: {
    photoAdsOnly: false,
    freeToContact: false,
    noDeposit: false,
  },
  keywords: { keywords: '' },
};

export default resetState;
