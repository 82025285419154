import React from 'react';
import RentRange from './rent_components/rent_range';
import PricePer from './rent_components/price_per';
import PriceIncludes from './rent_components/price_includes';

const Rent = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="rent-title"
  >
    <div id="rent-title" className="form-group__title">
      <span>
        {props.lookingForARoom ? 'Rent' : 'Budget'}{' '}
        <span aria-hidden="true">{props.currency}</span>
      </span>
    </div>
    <div>
      <RentRange
        updateRent={props.updateInput}
        minRent={props.rent.minRent}
        maxRent={props.rent.maxRent}
      />
      <PricePer
        rentPeriod={props.rent.per}
        updateRentPeriod={props.updateInput}
      />
    </div>
    {props.lookingForARoom ? (
      <PriceIncludes
        toggle={props.updateInput}
        billsCheck={props.rent.billsIncluded}
        feesCheck={props.rent.feesApply}
        country={props.country}
      />
    ) : null}
  </section>
);

export default Rent;
