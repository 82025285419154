import React from 'react';

const Footer = (props) => (
  <div className="search-filter__footer">
    {props.mobile ? (
      <div className="search-filter__reset-button">
        <a
          onClick={props.resetFilters}
          type="reset"
          role="button"
          tabIndex="0"
          className="button button--action"
        >
          Reset
        </a>
      </div>
    ) : null}
    <div className="search-filter__submit">
      <button className="button button--wide" onClick={props.validateForm} type="submit">
        { props.formSubmitting ? (
          <>
            <i aria-hidden="true" className="far fa-spinner fa-pulse" ></i>
          </>
        ) : (  
          <>
            Apply filters
          </>
        )}       
      </button>
    </div>
  </div>
);

export default Footer;
