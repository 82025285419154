import React from 'react';

const Keywords = (props) => (
  <section className="search-filter__section">
    <label htmlFor="keywords" className="form-group__title">
      <span className="sr-only">Search by </span>Keywords
    </label>
    <span>
      <input
        className="form-group__input"
        name="keyword"
        id="keywords"
        type="text"
        onChange={(e) => props.updateInput('keywords', e.target)}
        value={props.data.keywords || ''}
      />
    </span>
  </section>
);

export default Keywords;
