import React from 'react';
import ReactDOM from 'react-dom';
import Filters from './index';
import restructuredFilterData from './helpers/transform_filter_data';
import dataFetcher from './helpers/data_fetcher';
import { fetchData } from '../../../helpers/base_data_fetcher';
import trackEvent from '../../../helpers/gtm/eventPush';

const initialiseApp = (pageData, data, searchFiltersElement) => {
  const { yearsAvailable, searchType } = searchFiltersElement.dataset;
  ReactDOM.render(
    <Filters
      filterData={restructuredFilterData}
      country={data.country}
      currency={data.currency_symbol}
      mobile={data.mobile}
      // yearsAvailable={yearsAvailable}
      searchType={searchType}
      ref={(searchFilters) => {
        window.searchFilters = searchFilters;
      }}
      localisations={pageData.search.localisations}
    />,
    searchFiltersElement,
  );
};

const mountSearchFilters = (searchFiltersElement) => {
  // Fire tracking event
  trackEvent({
    category: 'narrow',
    action: 'filters_shown',
    label: '',
  });

  Promise.all([
    fetchData('page'),
    dataFetcher.getSiteData(),
  ]).then(([pageData, data]) =>
    initialiseApp(pageData, data, searchFiltersElement),
  );
};

export default mountSearchFilters;
