const flatshareTypeButtons = document.getElementsByName('flatshare_type');
const searchByArea = document.getElementById('searchByArea');

const filterForm = document.getElementById('filterForm');

export default function toggleFilterVisibility() {
  if (flatshareTypeButtons && filterForm && searchByArea) {
    flatshareTypeButtons.forEach((button) => {
      button.addEventListener('click', () => {
        if (button.value != 'wanted') {
          searchByArea.style.display = 'none';
        } else {
          searchByArea.style.display = '';
        }
      });
    });
  }
}
