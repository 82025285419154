import axios from 'axios';
import initialiseImpressionTracking from '../../components/list/impression_tracking';
import {
  trackPrimaryClick,
  trackSecondaryClick,
} from '../../components/list/click_tracking';
import mountSearchFilters from '../../components/list/search_filters/mount';
import mountSearchTools from '../../components/list/search_tools/mount';
import { fetchData } from '../../helpers/base_data_fetcher';
import '../../../sass/pages/list/adlist.scss';
import '../../components/case_study_banner/index.js';
import '../../components/live_rent_free_banner.js';
import '../../../sass/components/list/spotlight.scss';
import '../../../sass/components/list/coliving.scss';
import '../../../sass/components/list/building_header.scss';
import '../../../sass/components/message_box.scss';
import '../../../sass/components/list/broaden.scss';
import setCookiesForAdViews from '../../apps/reg_prompt_gated/set_cookies_for_ad_views';
import triggerRegistrationPrompt from '../../components/list/registration_prompt';
import triggerRegistrationPromptMobileList from '../../components/list/registration_prompt_mobile_with_exp_list';
import '../../experiments/offered_ad_promo_banner/experiment';
import srp_promo from './components/srp_promo';
import spotlightExperiment from '../../experiments/spotlights/index.js';
import trackEvent from '../../helpers/gtm/eventPush';
import toggleFilterVisibility from './components/search_filter.js';
import colivingInfoModal from './coliving_info_modal';
import trackBroadenSearch from './search_broaden_tracking';
import {
  trackFilteredResults,
  trackMarioResults,
  trackSearchFilter,
  removeFilterStorage,
} from './search_filters_tracking';

import './tracking_related_area_link.js';

colivingInfoModal();

if (typeof module !== 'undefined') {
  if (module && module.hot) {
    module.hot.accept();
  }
}

const results = document.querySelectorAll('.listing-result');
const mountFiltersElement = document.querySelector('#searchFilters');
const mountToolsElement = document.querySelector('#searchTools');

if (mountFiltersElement) {
  mountSearchFilters(mountFiltersElement);
}

if (mountToolsElement) {
  mountSearchTools(mountToolsElement);
}

function listingClickThrough(event, result) {
  if (window._sr.page.flatshare_type == 'offered') {
    setCookiesForAdViews();
  }
  const { listingId, listingUrl } = result.dataset;
  trackPrimaryClick(
    listingId,
    window.dataLayer,
    window._sr.ecommerce.impressions,
  );
}

function listingRightClickThrough(event, result) {
  const { listingId, listingUrl } = result.dataset;
  trackSecondaryClick(
    listingId,
    window.dataLayer,
    window._sr.ecommerce.impressions,
  );
}

function bindClickEvent(result) {
  const { listingId } = result.dataset;
  const links = result.querySelectorAll('[data-detail-url]');
  links.forEach((link) => {
    const { detailUrl } = link.dataset;
    if (detailUrl.includes('fad_click.pl?')) {
      link.href = detailUrl;
    }
    link.addEventListener('click', (event) => {
      listingClickThrough(event, result);
    });
    link.addEventListener('contextmenu', (event) =>
      listingRightClickThrough(event, result),
    );
  });
}

function listingSaved(button) {
  // ported from js/instant_shorlist.js
  const { href } = button;
  const newUrl = new URL(href);
  newUrl.searchParams.delete('function');
  newUrl.searchParams.delete('auto_return');
  newUrl.searchParams.set('mode', 'edit');
  button.setAttribute('data-save-type', 'saved');
  if (button.classList.contains('interaction-status--not-saved')) {
    button.classList.remove('interaction-status--not-saved');
    button.classList.add('interaction-status--saved');
  }
  button.classList.add('savedAd');
  const fa = button.querySelector('I');
  const span = button.querySelector('SPAN');
  span.textContent = ' Saved';
  fa.classList.remove('far');
  fa.classList.add('fas');
  return (button.href = newUrl.toString());
}

function saveListingLoggedOut(listingId) {
  const impression = _sr.ecommerce.impressions.find(
    (listing) => listing.id == listingId,
  );
  const labelString = `${impression.id}|${
    impression.brand == 'featured' ? 'f' : impression.position
  }`;
  window.dataLayer.push({
    ga_event_category: 'narrow',
    ga_event_action: 'saved_ads_add_not_logged_in ',
    ga_event_label: labelString,
    event: 'ga.event',
  });
}

function saveListing(button, listingId) {
  const { href } = button;
  const impression = _sr.ecommerce.impressions.find(
    (listing) => listing.id == listingId,
  );
  const labelString = `${impression.id}|${
    impression.brand == 'featured' ? 'f' : impression.position
  }`;
  axios.get(`${href}&format=json`).then((data) => {
    if (data.data.response_type == 'success') {
      if (data.data.result == 'added') {
        window.dataLayer.push({
          ga_event_category: 'narrow',
          ga_event_action: 'saved_ads_add',
          ga_event_label: labelString,
          event: 'ga.event',
        });
        return listingSaved(button);
      }
      return false;
    }
    return false;
  });
}

function bindEarlyBirdTracking(result) {
  const { listingId } = result.dataset;
  const early_bird_info = result.querySelector('.tooltip.earlyBird');
  let tooltip_shown = false;
  function fireTooltipShownEvent() {
    if (!tooltip_shown) {
      trackEvent({
        category: 'upgrade',
        action: 'ad_card_early_bird_info_show',
        label: `listing_id=${listingId}`,
      });
      tooltip_shown = true;
    }
  }
  if (early_bird_info) {
    const more_info_link = early_bird_info.querySelector(
      'a.tooltip_link_earlybird_access',
    );
    early_bird_info.addEventListener('mouseenter', function() {
      fireTooltipShownEvent();
    });
    early_bird_info.addEventListener('click', function() {
      fireTooltipShownEvent();
    });
    more_info_link.addEventListener('click', function() {
      trackEvent({
        category: 'upgrade',
        action: 'ad_card_early_bird_info_find_out_more',
        label: `listing_id=${listingId}`,
      });
    });
  }
}
function bindSaveEvent(result, isLoggedIn) {
  const { listingId } = result.dataset;
  if (isLoggedIn) {
    const saveButton = result.querySelector('[data-save-type]');
    if (!saveButton) {
      return false;
    }
    const { saveType } = saveButton.dataset;
    if (saveType != 'save') {
      return false;
    }

    return saveButton.addEventListener(
      'click',
      function(event) {
        event.stopPropagation();
        event.preventDefault();
        const button = this;
        saveListing(button, listingId);
      },
      { once: true },
    );
  }
  const loggedOutSaveButton = result.querySelector(
    'a.interaction-status--not-saved',
  );
  if (loggedOutSaveButton) {
    return loggedOutSaveButton.addEventListener('click', function(event) {
      event.stopPropagation();
      saveListingLoggedOut(listingId);
    });
  }
  return false;
}

const trackImpressions = (impressions) =>
  window.dataLayer.push({ ecommerce: impressions });

if (window._sr && window.dataLayer) {
  window._sr.ecommerce.impressions = [];
  const createImpression = initialiseImpressionTracking({
    page: _sr.page.offset,
    searchTitle: _sr.page.search_title,
    impressions: window._sr.ecommerce.impressions,
  });
  fetchData('session.loggedin').then((isLoggedIn) => {
    results.forEach((result) => {
      if (
        result.classList.contains('listing_sfm') ||
        result.classList.contains('promo-wap-banner') ||
        result.classList.contains('promo-offered-ad-banner') ||
        result.classList.contains('srp-promo') ||
        result.classList.contains('case-study-banner') ||
        result.classList.contains('liverentfree')
      ) {
        return;
      }
      // Might make more sense to pass the dataset through to createImpressions, this would further remove any reliance of browser apis.
      // console.log(result.dataset);
      createImpression(result);
      bindClickEvent(result);
      bindSaveEvent(result, isLoggedIn);
      bindEarlyBirdTracking(result);
    });
    trackImpressions(window._sr.ecommerce);
  });
}

// hook the search filter / refine button up with the actual filters

const filterLink = document.querySelector('.search-actions__filter-link');
if (filterLink) {
  filterLink.addEventListener('click', () =>
    window.searchFilters.showFilters(),
  );
}

function triggerYouTubePromo({ id, card }) {
  const video = card.querySelector('iframe');
  const videoSource = video.src;
  return (video.src = `${videoSource}&autoplay=1`);
}

function triggerVimeoPromo({ id, card }) {
  const video = card.querySelector('iframe');
  const videoSource = video.src;
  return (video.src = `${videoSource}&autoplay=1`);
}

const promoVideoAds = document.querySelectorAll('.videoPromo');
if (promoVideoAds.length) {
  promoVideoAds.forEach((promo) => {
    const { videoType, videoId } = promo.dataset;
    promo.addEventListener('click', (event) => {
      if (videoType == 'youtube') {
        triggerYouTubePromo({ id: videoId, card: promo });
      }
      if (videoType == 'vimeo') {
        triggerVimeoPromo({ id: videoId, card: promo });
      }
      promo.classList.add('revealVideo');
    });
  });
}

if (window._sr.site.mobile) {
  triggerRegistrationPromptMobileList();
} else {
  triggerRegistrationPrompt();
}

spotlightExperiment();
srp_promo();
toggleFilterVisibility();
trackFilteredResults();
trackBroadenSearch();
trackMarioResults();
trackSearchFilter();
removeFilterStorage();
