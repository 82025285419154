import storageAvailable from '../../helpers/storage_available';
import trackEvent from '../../helpers/gtm/eventPush';

// Function to get filter_applied from localstorage
const getFilterLabel = () => {
  if (storageAvailable('localStorage')) {
    const storedFilterValue = localStorage.getItem('filter_applied');
    if (storedFilterValue && storedFilterValue.startsWith('js:')) {
      const match = storedFilterValue.match(/flatshare_type=(.*)/);
      if (match && match[1]) {
        return match[1];
      }
    }
    return storedFilterValue;
  }
  return null;
};

// Function to get current date and time e.g YYYY-MM-DD HH:MM:SS
const getCurrentDateTime = () => {
  const now = new Date();
  const { year, month, day, hours, minutes, seconds } = {
    year: now.getFullYear(),
    month: String(now.getMonth() + 1).padStart(2, '0'),
    day: String(now.getDate()).padStart(2, '0'),
    hours: String(now.getHours()).padStart(2, '0'),
    minutes: String(now.getMinutes()).padStart(2, '0'),
    seconds: String(now.getSeconds()).padStart(2, '0'),
  };
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

// Function to select result data elements from DOM
const getResultsData = () => {
  return document.querySelector(
    '[data-search-result-total], [data-total-results]',
  );
};

// Function to get num results from selected DOM element
const getResultsCount = (resultsData) => {
  return (
    resultsData?.dataset?.totalResults ||
    resultsData?.dataset?.searchResultTotal ||
    '0'
  );
};

// Function to remove the filter_applied value in local storage
const removeFilterStorage = () => {
  if (storageAvailable('localStorage')) {
    localStorage.removeItem('filter_applied');
    localStorage.removeItem('mario_filtered_search');
  }
};

// Function to track applied SRP filters
const trackFilteredResults = () => {
  const resultsData = getResultsData();

  if (resultsData && storageAvailable('localStorage')) {
    const filteredSearch = localStorage.getItem('filter_applied');
    if (!filteredSearch) return;

    const time = getCurrentDateTime();
    const results = getResultsCount(resultsData);

    // Determine filterName and action
    let filterName;
    let action;
    if (filteredSearch.startsWith('js:')) {
      filterName = getFilterLabel();
      action = 'filtered_search';
    } else {
      filterName = filteredSearch;
      action =
        filteredSearch === 'filtered_search_reset'
          ? 'filtered_search_reset'
          : 'filtered_search';
    }

    // Track the event
    trackEvent({
      category: 'narrow',
      action,
      label: `results=${results}|time=${time}|filter=${filterName}`,
    });
  }
};

// Function to track Mario searches on SRP
const trackMarioResults = () => {
  const resultsData = getResultsData();

  if (resultsData && storageAvailable('localStorage')) {
    const marioFilteredSearch = localStorage.getItem('mario_filtered_search');
    if (!marioFilteredSearch) return;

    const marioTracked = Number(
      localStorage.getItem('mario_results_tracked') || 1,
    );
    const time = getCurrentDateTime();
    const results = getResultsCount(resultsData);

    // Track the event
    trackEvent({
      category: 'narrow',
      action: 'filtered_search',
      label: `results=${results}|time=${time}|mario=${marioTracked}|filter=${marioFilteredSearch}`,
    });

    // Update mario_results_tracked
    localStorage.setItem('mario_results_tracked', marioTracked + 1);
  }
};

function filterApplied() {
  const filter_label = getFilterLabel();
  const currentDate = getCurrentDateTime();

  trackEvent({
    category: 'narrow',
    action: 'srp_search_widget_search_radius_change',
    label: `${currentDate}|${filter_label}`,
  });
}

const compareSearches = (search1, search2, element) => {
  let theSame = true;
  for (let i = element; i < search1.length; i += 1) {
    if (search1[i] !== search2[i]) {
      theSame = false;
    }
  }
  if (element == 1 && search1[0] == search2[0]) {
    theSame = false;
  }
  return theSame;
};

const trackSearchFilter = function() {
  const searchWidget = document.querySelector('#searchWidget');
  if (!searchWidget) {
    return;
  }

  const submitButton = document.querySelector('#submitButton');
  const typeOffered = document.querySelector('#flatshare_type-offered');
  const typeWanted = document.querySelector('#flatshare_type-wanted');
  const typeBuddyUp = document.querySelector('#flatshare_type-buddyup');
  const locationField = document.querySelector('#search_by_location_field');
  const radius = document.querySelector('#radius');

  const formCurrentData = {
    current_radius: radius ? radius.value : 'NA',
    current_locn: locationField.value,
    current_type_offered: typeOffered.checked,
    current_type_wanted: typeWanted.checked,
    current_type_buddy_up: typeBuddyUp ? typeBuddyUp.checked : 'NA',
  };

  submitButton.addEventListener('click', () => {
    const formTargetData = {
      target_radius: radius ? radius.value : 'NA',
      target_locn: locationField.value,
      target_type_offered: typeOffered.checked,
      target_type_wanted: typeWanted.checked,
      target_type_buddy_up: typeBuddyUp ? typeBuddyUp.checked : 'NA',
    };

    const currentlabel = Object.entries(formCurrentData)
      .map(([key, value]) => `${key}=${value}`)
      .join('|');

    const targetlabel = Object.entries(formTargetData)
      .map(([key, value]) => `${key}=${value}`)
      .join('|');

    const currentlabelCompare = Object.entries(formCurrentData).map(
      ([key, value]) => `${value}`,
    );
    const targetlabelCompare = Object.entries(formTargetData).map(
      ([key, value]) => `${value}`,
    );

    if (!compareSearches(currentlabelCompare, targetlabelCompare, 0)) {
      trackEvent({
        category: 'narrow',
        action: 'srp_search_widget_search',
        label: `${currentlabel}|${targetlabel}`,
      });
    }
    if (compareSearches(currentlabelCompare, targetlabelCompare, 1)) {
      filterApplied();
    }
  });
};

export {
  trackSearchFilter,
  trackFilteredResults,
  trackMarioResults,
  removeFilterStorage,
};
