import React from 'react';
import SelectInput from '../inputs/select';
import Checkbox from '../inputs/checkbox';

const rooms = [
  { value: '2', text: '2+ room for rent' },
  { value: '3', text: '3+ room for rent' },
];

const NumberOfRooms = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="number-of-rooms-title"
  >
    <div id="number-of-rooms-title" className="search-filters__number-of-rooms">
      <div className="form-group__title">
        <span>Number of rooms</span>
      </div>
      <Checkbox
        name={'show_no_of_rooms'}
        labelText={'Looking for more than 1 room'}
        id={'numOfRoomsVisibile'}
        onChange={props.toggleNumberOfRooms}
        check={props.numOfRoomsVisible}
      />
      {props.numOfRoomsVisible ? (
        <SelectInput
          name={'no_of_rooms'}
          id={'numOfRooms'}
          options={rooms}
          onChange={(e) => props.updateSelect('numberOfRooms', e.target)}
          selected={props.numberOfRooms}
        />
      ) : null}
    </div>
  </section>
);

export default NumberOfRooms;
