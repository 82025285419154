import React from 'react';
import SelectInput from '../inputs/select';

const SizeOfHousehold = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="size-of-household-title"
  >
    <div>
      <div id="size-of-household-title" className="form-group__title">
        <span>Size of household</span>
      </div>
      <div className="form_group__hint">
        (total number of bedrooms in the property)
      </div>
      <div>
        <label className="sr-only" htmlFor="minBeds">
          Minimum Beds
        </label>
        <SelectInput
          name={'min_beds'}
          id={'minBeds'}
          inline={true}
          options={min}
          onChange={(e) => props.updateSelect('sizeOfHousehold', e.target)}
          selected={props.data.minBeds}
        />
        <span className="form-group__text">to</span>
        <label className="sr-only" htmlFor="maxBeds">
          Maximum Beds
        </label>
        <SelectInput
          name={'max_beds'}
          id={'maxBeds'}
          options={max}
          onChange={(e) => props.updateSelect('sizeOfHousehold', e.target)}
          selected={props.data.maxBeds}
        />
      </div>
    </div>
  </section>
);

const min = [
  { value: '', text: '-' },
  { value: '2', text: '2' },
  { value: '3', text: '3' },
  { value: '4', text: '4' },
  { value: '5', text: '5' },
];

const max = [
  { value: '', text: '-' },
  { value: '2', text: '2' },
  { value: '3', text: '3' },
  { value: '4', text: '4' },
  { value: '5', text: '5' },
  { value: '6+', text: '6+' },
];

export default SizeOfHousehold;
