import React from 'react';

const NumberInput = (props) => (
  <span>
    <input
      className="form-group__input form-group__input--inline"
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      type="number"
      min={props.min}
      max={props.max}
      size={props.size}
      step="1"
      onChange={(event) => props.onChange(event)}
      // defaultValue={props.value}
      value={props.value || ''}
      style={props.styles}
    />
  </span>
);

export default NumberInput;
