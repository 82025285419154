import React from 'react';
import NumberInput from '../inputs/number';

const RoomsSuitableForAgesRange = (props) => {
  return (
    <section
      className="search-filter__section"
      role="group"
      aria-labelledby="rooms-suitable-for-ages-range-title"
    >
      <div className="form-group__title" id="rooms-suitable-for-ages-range-title">
        <span>Rooms suitable for ages</span>
      </div>
      <NumberInput
        name={'min_suitable_age'}
        id={'minSuitableAge'}
        placeholder={'min'}
        min={'18'}
        max={'99'}
        size={'3'}
        onChange={(e) => props.updateInput('roomsSuitableForAges', e.target)}
        value={props.data.minSuitableAge}
        styles={{ minWidth: '70px' }}
      />
      <span className="form-group__text">to</span>
      <NumberInput
        name={'max_suitable_age'}
        id={'maxSuitableAge'}
        placeholder={'max'}
        min={'18'}
        max={'99'}
        size={'3'}
        onChange={(e) => props.updateInput('roomsSuitableForAges', e.target)}
        value={props.data.maxSuitableAge}
        styles={{ minWidth: '70px' }}
      />
      {props.errorMessage ? (
        <p className="form-group__hint search-filter__error-message">
          {props.errorMessage}
        </p>
      ) : null}
    </section>
  )
};

export default RoomsSuitableForAgesRange;
