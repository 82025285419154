import showRegPromptAfterViews from '../../apps/reg_prompt_aff/afterViews';
import { fetchData } from '../../helpers/base_data_fetcher';
import getActiveExperimentVariant from '../../experiments/helpers/optimize.js';
import showRegPromptAfterOneMinute from '../../apps/reg_prompt/afterOneMinute';
import blockingRegPopup5ViewsMobileExp from '../../experiments/reg_prompt_gated_mobile/afterViews';

async function triggerExperiment(experiment_id) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: `optimize.activate.blocking_reg_popup_close_button_5_views_mobile`,
    });
    try {
      const variant = await getActiveExperimentVariant(experiment_id);
      if (variant != 0) {
        blockingRegPopup5ViewsMobileExp();
      } else {
        showRegPromptAfterViews();
      }
    } catch (error) {
      console.log(error.message);
      showRegPromptAfterViews();
    }
  }
}

const triggerRegistrationPromptMobileList = async () => {
  try {
    const [
      isLoggedIn,
      knownUser,
      affiliate,
      features,
      experiments,
    ] = await Promise.all([
      fetchData('session.loggedin'),
      fetchData('session.user_known'),
      fetchData('affiliate'),
      fetchData('config.features'),
      fetchData('experiments'),
    ]);
    if (isLoggedIn) {
      return false;
    }
    const { blocking_reg_prompt, one_minute_reg_prompt } = features;
    const { blocking_reg_popup_close_button_5_views_mobile } = experiments;

    if (affiliate.category !== 'adwords' && one_minute_reg_prompt) {
      return showRegPromptAfterOneMinute();
    }
    if (affiliate.category === 'adwords' && blocking_reg_prompt) {
      if (!knownUser && blocking_reg_popup_close_button_5_views_mobile) {
        triggerExperiment(blocking_reg_popup_close_button_5_views_mobile);
      } else {
        return showRegPromptAfterViews();
      }
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default triggerRegistrationPromptMobileList;
