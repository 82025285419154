import React from 'react';

const RadioButton = props => (
  <div className="form-group__radio">
    <input
      className="form-group__radio"
      data-testid="radioButton"
      type="radio"
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={event => props.handleChange(event)}
      onBlur={props.handleBlur}
      tabIndex={props.tabIndex}
      // defaultChecked={props.defaultChecked}
      checked={props.checked}
    />
    <label htmlFor={props.id}>{props.labelText}</label>
  </div>
);

export default RadioButton;
