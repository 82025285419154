import Cookies from 'js-cookie';

export default function setCookies() {
  const countCookie =
    Cookies.get('advert_view_count') ||
    (Cookies.get('regpopup_count') &&
      parseInt(Cookies.get('regpopup_count'), 10) + 1);
  if (!countCookie) {
    return Cookies.set('advert_view_count', 1, { expires: 3 });
  }
  return Cookies.set('advert_view_count', parseInt(countCookie, 10) + 1, {
    expires: 3,
  });
}
