import React from 'react';
import '../../../../sass/components/list/search_box.scss';

const SearchTools = (props) => (
  <div className="search-tools" data-testid="searchTools">
    <div className="search-tools__actions">
      {props.searchType === 'offered' ? (
        <a className="button button--action search-tools__actions-map-link" href={props.mapUrl}>
          <div className="button__content">
            <span className="button__icon button__icon--left">
              <i className="far fa-map-marker-alt" />
            </span>
            <span className="button__text">Map</span>
          </div>
        </a>
      ) : null}
      <div className="search-tools__sort">
        <label className="search-tools__sort-title" htmlFor="sort-by">
          <span className="search-tools__sort-icon">
            <i className="far fa-sort-amount-down" />
          </span>
          Sort <span className="search-tools__sort-title-long">by</span>
        </label>
        <div className="search-tools__select">
          <select
            id="sort-by"
            onChange={(event) => props.updateSortBy(event.target.value)}
            value={props.sortBy}
          >
            <option value="">Default sort order</option>
            <option value="days_since_placed">Newest ads</option>
            <option value="last_updated">Last updated</option>
            <option value="price_low_to_high">Price (lowest first)</option>
            <option value="price_high_to_low">Price (highest first)</option>
          </select>
          <div className="search-tools__select-icon">
            <i className="far fa-chevron-down" aria-hidden />
          </div>
        </div>
      </div>
      <a
        href="#minRent"
        id="open_search_filters"
        className="button button--action"
        onClick={props.showHideFilters}
      >
        <div className="button__content">
          <span className="button__icon button__icon--left">
            <i className="far fa-filter filter__icon">
              {props.numberOfFiltersUsed > 0 ? (
                <div className="filters-applied" />
              ) : null}
            </i>
          </span>
          <span className="button__text">Filter</span>
        </div>
      </a>
    </div>
    <div className="search-tools__results">
      {props.resultTotal < 1 ? (
        'Sorry, no matching ads found'
      ) : (
        <span>
          <span className="search-tools__text-bold">
            {props.resultTotal > 1000 ? '1000+' : `${props.resultTotal}`}
          </span>{' '}
          Results{' '}
          {props.numberOfFiltersUsed > 0 ? (
            <span>
              with{' '}
              <span className="search-tools__filter-count">
                {props.numberOfFiltersUsed} Filter
                {props.numberOfFiltersUsed > 1 ? 's' : null}
              </span>
              <a
                onClick={props.showHideFilters}
                className="search-tools__filter-count-link"
              >
                {props.numberOfFiltersUsed} Filter
                {props.numberOfFiltersUsed > 1 ? 's' : null}
              </a>
            </span>
          ) : null}
        </span>
      )}{' '}
      {props.saveUrl ? (
        <a href={props.saveUrl} className="search-tools__save">
          Save search
        </a>
      ) : null}
    </div>
  </div>
);

export default SearchTools;
