import React from 'react';
import RadioButton from '../inputs/radio';

const PostedBy = (props) => {
  return (
    <section
      className="search-filter__section"
      role="group"
      aria-labelledby="posted-by-title"
    >
      <div id="posted-by-title" className="form-group__title">
        <span>Posted by</span>
      </div>
      <div className="search-filter__radio-wrapper">
        <RadioButton
          name={'posted_by'}
          id={'dontMind'}
          value={''}
          checked={props.data.posted_by === ''}
          handleChange={(e) => props.updateInput('postedBy', e.target)}
          labelText={`Don't mind`}
        />
        <RadioButton
          name={'posted_by'}
          id={'privateLandlords'}
          value={'private_landlords'}
          checked={props.data.posted_by === 'private_landlords'}
          handleChange={(e) => props.updateInput('postedBy', e.target)}
          labelText={'Just private ads (no agents)'}
        />
        {props.originalPostedByValue === 'agents' ? (
          <RadioButton
            name={'posted_by'}
            id={'agents'}
            value={'agents'}
            checked={props.data.posted_by === 'agents'}
            handleChange={(e) => props.updateInput('postedBy', e.target)}
            labelText={'Just agents'}
          />
        ) : null}
        {props.originalPostedByValue === 'co_live' ? (
          <RadioButton
            name={'posted_by'}
            id={'coLive'}
            value={'co_live'}
            checked={props.data.posted_by === 'co_live'}
            handleChange={(e) => props.updateInput('postedBy', e.target)}
            labelText={'Just co lives'}
          />
        ) : null}
        <RadioButton
          name={'posted_by'}
          id={'flatMates'}
          value={'flatmates'}
          checked={props.data.posted_by === 'flatmates'}
          handleChange={(e) => props.updateInput('postedBy', e.target)}
          labelText={props.localisations.posted_by_flatmates}
        />
        <RadioButton
          name={'posted_by'}
          id={'liveIn'}
          value={'live_in'}
          checked={props.data.posted_by === 'live_in'}
          handleChange={(e) => props.updateInput('postedBy', e.target)}
          labelText={'Just live in landlords'}
        />
        <RadioButton
          name={'posted_by'}
          id={'liveOut'}
          value={'live_out'}
          checked={props.data.posted_by === 'live_out'}
          handleChange={(e) => props.updateInput('postedBy', e.target)}
          labelText={'No live in landlords'}
        />
      </div>
    </section>
  );
};

export default PostedBy;
