import trackEvent from '../../helpers/gtm/eventPush';

function trackPromoView({
  category = 'banners',
  action = 'srp_promo_panel_view',
  promo_type = '',
  position = 0,
  this_page = '/',
  target = '',
  is_randomised = false,
} = {}) {
  trackEvent({
    category,
    action,
    label: `design=${promo_type}|posn=${position}|rnd=${
      is_randomised ? '1' : '0'
    }|current=${this_page}`,
    non_interaction: true,
  });
}

function trackPromoClick({
  category = 'banners',
  action = 'srp_promo_panel_click',
  promo_type = '',
  position = 0,
  this_page = '/',
  target = '',
  is_randomised = false,
} = {}) {
  trackEvent({
    category,
    action,
    label: `design=${promo_type}|posn=${position}|rnd=${
      is_randomised ? '1' : '0'
    }|current=${this_page}|target=${target}`,
  });
}

export { trackPromoView, trackPromoClick };
