import React from 'react';

const SelectInput = (props) => (
  <div
    className={`${
      props.inline
        ? 'form-group__select form-group__input--inline'
        : 'form-group__select'
    }`}
  >
    <select
      name={props.name}
      id={props.id}
      value={props.selected}
      onChange={(e) => props.onChange(e)}
    >
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
    <span className="form-group__select-icon">
      <i className="far fa-chevron-down" aria-hidden />
    </span>
  </div>
);

export default SelectInput;
