import { fetchData } from '../../helpers/base_data_fetcher';
import getActiveExperimentVariant from '../helpers/optimize';
import offeredAdPromoBanner from './offered_ad_promo_banner';

const results = document.querySelectorAll('.listing-result');

const offeredPromptMountElement = document.querySelector(
  '#promo_banner_offered_ad',
);

const offeredAdPromoExperiment = ({ mount_element, country }) => {
  mount_element.classList.add('promo-offered-ad-banner--loaded');
  if (results.length > 1) {
    const secondElement = results[1];
    secondElement.after(mount_element);
    offeredAdPromoBanner({
      mount_element,
      country,
    });
  } else {
    offeredAdPromoBanner({
      mount_element,
      country,
    });
  }
};

async function triggerExperiment({
  offered_ad_promo_experiment_id,
  is_mobile,
  mount_element,
  country,
}) {
  if (window.dataLayer) {
    if (country == 'GB') {
      if (is_mobile) {
        window.dataLayer.push({
          event: 'optimize.activate.offered_ad_promo_mobile_logged_in',
        });
      } else {
        window.dataLayer.push({
          event: 'optimize.activate.offered_ad_promo_desktop_logged_in',
        });
      }
    } else if (country == 'US') {
      if (is_mobile) {
        window.dataLayer.push({
          event: 'optimize.activate.offered_ad_promo_mobile_logged_out_us',
        });
      } else {
        window.dataLayer.push({
          event: 'optimize.activate.offered_ad_promo_desktop_logged_out_us',
        });
      }
    }
  }
  try {
    const variant = await getActiveExperimentVariant(
      offered_ad_promo_experiment_id,
    );
    if (variant != 0) {
      offeredAdPromoExperiment({ mount_element, country });
    }
  } catch (error) {
    console.log(error.message);
  }
}

if (offeredPromptMountElement) {
  Promise.all([
    fetchData('experiments'),
    fetchData('site'),
    fetchData('page.flatshare_type'),
    fetchData('session'),
    fetchData('customer'),
  ]).then(([experiments, site, flatshare_type, session, customer]) => {
    if (
      flatshare_type == 'wanted' &&
      session.loggedin &&
      site.country == 'GB' &&
      customer.initial_type == 'offerer'
    ) {
      const is_mobile = !!site.mobile;
      const {
        offered_ad_promo_mobile_logged_in,
        offered_ad_promo_desktop_logged_in,
      } = experiments;
      triggerExperiment({
        offered_ad_promo_experiment_id: is_mobile
          ? offered_ad_promo_mobile_logged_in
          : offered_ad_promo_desktop_logged_in,
        is_mobile,
        mount_element: offeredPromptMountElement,
        country: site.country,
      });
    }
    if (
      flatshare_type == 'wanted' &&
      !session.loggedin &&
      site.country == 'US'
    ) {
      const is_mobile = !!site.mobile;
      const {
        offered_ad_promo_mobile_logged_out_us,
        offered_ad_promo_desktop_logged_out_us,
      } = experiments;
      triggerExperiment({
        offered_ad_promo_experiment_id: is_mobile
          ? offered_ad_promo_mobile_logged_out_us
          : offered_ad_promo_desktop_logged_out_us,
        is_mobile,
        mount_element: offeredPromptMountElement,
        country: site.country,
      });
    }
  });
}
