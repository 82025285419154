const state = {
  resultsIndex: 1,
  featuredIndex: 1,
};

function toSnakeCase(string) {
  return string
    .toLowerCase()
    .split(' ')
    .join('_');
}

function incrementResultsIndex() {
  return (state.resultsIndex += 1);
}

function getPropertyType(rooms, propertyType, propertyTypeMore) {
  return propertyTypeMore || `${rooms} bed ${propertyType}`;
}

function transformAvailable(availableNow) {
  return availableNow == 1 ? 'yes' : 'no';
}

function getPosition(brand) {
  let position;
  if (brand == 'featured') {
    position = state.page + state.featuredIndex;
    state.featuredIndex += 1;
    return position;
  }
  position = state.page + state.resultsIndex;
  incrementResultsIndex();
  return position;
}

function transformResult(result) {
  const earlyBird = result.getAttribute('data-listing-early-bird') == 'Early Bird';
  const brand = earlyBird
    ? 'early_bird'
    : result.getAttribute('data-listing-brand');
  return {
    name: result.getAttribute('data-listing-title'),
    id: result.getAttribute('data-listing-id'),
    brand: brand,
    list: state.searchTitle,
    dimension11: result.getAttribute('data-listing-days-old'),
    dimension12: transformAvailable(
      result.getAttribute('data-listing-available'),
    ),
    position: getPosition(brand),
  };
}

function transformCustomerResult(result) {
  const roomsSought = toSnakeCase(
    result.getAttribute('data-listing-rooms-sought'),
  );
  const neighbourhood = result
    .getAttribute('data-listing-example-matching-area')
    .split()
    .map(toSnakeCase)
    .join();

  const impression = transformResult(result);
  impression.category = `wanted/${toSnakeCase(roomsSought)}/${neighbourhood}`;
  impression.variant = result
    .getAttribute('data-listing-gender-and-occupation')
    .trim();
  impression.dimension13 = result.getAttribute('data-listing-number-of-rooms');
  impression.dimension14 = roomsSought;
  return impression;
}

function transformPropertyResult(result) {
  const rooms = result.getAttribute('data-listing-rooms-in-property');
  const propertyType = result.getAttribute('data-listing-property-type');
  const propertyTypeMore = result.getAttribute(
    'data-listing-property-type-more',
  );
  const accomodationType = result.getAttribute('data-listing-scp') == 'Y' ? 'whole_property' : 'room';
  const neighbourhood = toSnakeCase(
    result.getAttribute('data-listing-neighbourhood'),
  );
  const postcode = result.getAttribute('data-listing-postcode');

  const impression = transformResult(result);
  impression.category = `offered/${accomodationType}/${neighbourhood}/${postcode}`;
  impression.variant = result.getAttribute('data-listing-advertiser-role');
  impression.dimension13 = rooms;
  impression.dimension14 = getPropertyType(
    rooms,
    propertyType,
    propertyTypeMore,
  );
  impression.dimension22 = result.getAttribute('data-listing-status');
  return impression;
}

function createImpression(result) {
  const listing_type = result.getAttribute('data-listing-type');
  if (listing_type == 'offered') {
    state.impressions.push(transformPropertyResult(result));
  }
  if (listing_type == 'wanted' || listing_type == 'buddyup') {
    state.impressions.push(transformCustomerResult(result));
  }
}

const initialise = (config) => {
  const { page, searchTitle, impressions } = config;
  state.page = page;
  state.searchTitle = searchTitle;
  state.impressions = impressions;
  return createImpression;
};

export default initialise;
