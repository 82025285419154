import React from 'react';
import NumberInput from '../inputs/number';

const AgeRange = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="age-range-title"
  >
    <div className="form-group__title" id="age-range-title">
      <span>{props.title}</span>
    </div>
    <NumberInput
      name={'min_age_req'}
      id={'minAge'}
      placeholder={'min'}
      min={'18'}
      max={'99'}
      size={'3'}
      onChange={(e) => props.updateInput('ageRange', e.target)}
      value={props.data.minAge}
      styles={{ minWidth: '70px' }}
    />
    <span className="form-group__text">to</span>
    <NumberInput
      name={'max_age_req'}
      id={'maxAge'}
      placeholder={'max'}
      min={'18'}
      max={'99'}
      size={'3'}
      onChange={(e) => props.updateInput('ageRange', e.target)}
      value={props.data.maxAge}
      styles={{ minWidth: '70px' }}
    />
  </section>
);

export default AgeRange;
