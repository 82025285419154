import initialiseImpressionTracking from './impression_tracking';
import setCookiesForAdViews from '../../apps/reg_prompt_gated/set_cookies_for_ad_views';

function getListing(listingId, impressions) {
  return impressions.find((product) => product.id == listingId);
}

function listingClickThrough(event, result) {
  const { listingId, listingUrl } = result.dataset;
  if (window._sr.page.flatshare_type == 'offered') {
    setCookiesForAdViews();
  }
  trackPrimaryClick(
    listingId,
    window.dataLayer,
    window._sr.ecommerce.impressions,
  );
}

function listingRightClickThrough(event, result) {
  const { listingId, listingUrl } = result.dataset;
  trackSecondaryClick(
    listingId,
    window.dataLayer,
    window._sr.ecommerce.impressions,
  );
}

function trackClick(listingId, dataLayer, impressions, event) {
  const product = getListing(listingId, impressions);
  const { list } = product;
  delete product.list;
  return dataLayer.push({
    event,
    ecommerce: {
      click: {
        actionField: { list: list },
        products: [product],
      },
    },
  });
}

export function trackPrimaryClick(listingId, dataLayer, impressions) {
  trackClick(listingId, dataLayer, impressions, 'productClick');
}

export function trackSecondaryClick(listingId, dataLayer, impressions) {
  trackClick(listingId, dataLayer, impressions, 'productClickRight');
}

export function bindClickEvent(result) {
  const link = result.querySelector('.listing-card__link');
  const { listingUrl } = result.dataset;
  if (listingUrl.includes('/flatshare/fad_click.pl?')) {
    link.href = listingUrl;
  }
  if (listingUrl.includes('/roommate/fad_click.pl?')) {
    link.href = listingUrl;
  }
  link.addEventListener('click', (event) => {
    listingClickThrough(event, result);
  });
  link.addEventListener('contextmenu', (event) =>
    listingRightClickThrough(event, result),
  );
}

window._sr.ecommerce.impressions = [];
export const createImpression = initialiseImpressionTracking({
  page: _sr.page.offset,
  searchTitle: _sr.page.search_title,
  impressions: window._sr.ecommerce.impressions,
});

export const trackImpressions = (impressions) =>
  window.dataLayer.push({ ecommerce: impressions });
