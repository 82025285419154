import React from 'react';
import CheckboxButton from '../inputs/checkbox';
import RadioButton from '../inputs/radio';

const DaysAvailable = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="days-available-title"
  >
    <div id="days-available-title" className="form-group__title">
      <span>
        {props.lookingForARoom ? 'Days available' : 'Required availabilty'}
      </span>
    </div>
    <div className="search-filter__radio-wrapper">
      <RadioButton
        name={'days_of_wk_available'}
        id={'allPeriods'}
        value={''}
        checked={props.data.days_of_wk_available === ''}
        handleChange={(e) => props.updateInput('daysAvailable', e.target)}
        labelText={'All Periods'}
      />
      <RadioButton
        name={'days_of_wk_available'}
        id={'wholeWeek'}
        value={'7 days a week'}
        checked={props.data.days_of_wk_available === '7 days a week'}
        handleChange={(e) => props.updateInput('daysAvailable', e.target)}
        labelText={'7 days per week'}
      />
      <RadioButton
        name={'days_of_wk_available'}
        id={'monToFri'}
        value={'Mon to Fri only'}
        checked={props.data.days_of_wk_available === 'Mon to Fri only'}
        handleChange={(e) => props.updateInput('daysAvailable', e.target)}
        labelText={'Mon to Fri only'}
      />
      <RadioButton
        name={'days_of_wk_available'}
        id={'weekendsOnly'}
        handleChange={(e) => props.updateInput('daysAvailable', e.target)}
        value={'Weekends only'}
        checked={props.data.days_of_wk_available == 'Weekends only'}
        labelText={'Weekends only'}
      />
    </div>
    {props.lookingForARoom ? (
      <>
        <hr className="search-filter__separator" />
        <CheckboxButton
          onChange={(e) => props.updateInput('daysAvailable', e.target)}
          name={'short_lets_considered'}
          id={'shortLets'}
          value={'Y'}
          check={props.data.shortLets}
          labelText={
            props.country == 'US' ? 'Short term rentals' : 'Short term lets'
          }
        />
      </>
    ) : null}
  </section>
);

export default DaysAvailable;
