import React, { useState } from 'react';
import Modal from '../../../components/Modal/index';
import '../../../../sass/pages/list/promo_video_info_modal.scss';
import trackEvent from '../../../helpers/gtm/eventPush';

const ColivingTooltipInfoModal = ({ locale }) => {
  const [showDialog, updateShowDialog] = useState(false);

  const close = () => {
    updateShowDialog(false);
  };

  const open = () => {
    updateShowDialog(true);
  };

  const tooltipIcons = document.querySelectorAll('.coliving-card__label-icon');

  tooltipIcons.forEach((icon) =>
    icon.addEventListener('click', (event) => {
      open();
      event.preventDefault();
    }),
  );

  return (
    <>
      {showDialog ? (
        <Modal
          close={close}
          showDialog={showDialog}
          modalLabelText="Coliving"
          id="colivingModal"
          initialFocus=".modal__close"
        >
          {locale == 'en-US' ? (
            <>
              <p className="coliving-modal__paragraph">
                Co-living communities are purpose-built apartment blocks for
                renters. You rent a shared apartment or self-contained studio,
                with all utilities included, while having access to huge shared
                facilities, such as communal kitchens, communal living spaces,
                co-working spaces, or even a gym.
              </p>
              <p className="coliving-modal__paragraph">
                It could just be the best of both worlds. Your own space when
                you need it, and plenty of company and organized events and
                activities when you don&apos;t.
              </p>
            </>
          ) : (
            <>
              <p className="coliving-modal__paragraph">
                Co-living communities are purpose-built apartment blocks for
                renters. You rent a self-contained studio apartment, with all
                bills included, while having access to huge shared facilities,
                such as communal kitchens, communal living spaces, co-working
                spaces, or even a gym.
              </p>
              <p className="coliving-modal__paragraph">
                It could just be the best of both worlds. Your own space when
                you need it, and plenty of company and organised events and
                activities when you don&apos;t.
              </p>
            </>
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default ColivingTooltipInfoModal;
