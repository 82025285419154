import React from 'react';
import RadioButton from '../inputs/radio';

const RoomsFor = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="rooms-for-title"
  >
    <div id="rooms-for-title" className="form-group__title">
      <span>Rooms for</span>
    </div>
    <div className="search-filter__radio-wrapper">
      <RadioButton
        name={'rooms_for'}
        id={'anyGenderShare'}
        value={''}
        checked={props.data.rooms_for === ''}
        handleChange={(e) => props.updateInput('roomsFor', e.target)}
        labelText={"Don't Mind"}
      />
      <RadioButton
        name={'rooms_for'}
        id={'femalesShare'}
        value={'females'}
        checked={props.data.rooms_for === 'females'}
        handleChange={(e) => props.updateInput('roomsFor', e.target)}
        labelText={'Females'}
      />
      <RadioButton
        name={'rooms_for'}
        id={'malesShare'}
        handleChange={(e) => props.updateInput('roomsFor', e.target)}
        value={'males'}
        checked={props.data.rooms_for == 'males'}
        labelText={'Males'}
      />
      <RadioButton
        name={'rooms_for'}
        id={'couplesShare'}
        value={'couples'}
        checked={props.data.rooms_for === 'couples'}
        handleChange={(e) => props.updateInput('roomsFor', e.target)}
        labelText={'Couples'}
      />
    </div>
  </section>
);

export default RoomsFor;
