import React from 'react';
import CheckboxButton from '../inputs/checkbox';
import RadioButton from '../inputs/radio';

const PropertyPreferences = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="property-preferences-title"
  >
    <div id="property-preferences-title" className="form-group__title">
      <span>
        {props.lookingForARoom
          ? 'Property preferences'
          : 'Required property preferences'}
      </span>
    </div>
    <div className="search-filter__radio-wrapper">
      <RadioButton
        name={'smoking'}
        id={'smokingIndifferent'}
        value={''}
        checked={props.data.smoking === ''}
        handleChange={(e) => props.updateInput('propertyPreferences', e.target)}
        labelText={'Smoking or non-smoking'}
      />
      <RadioButton
        name={'smoking'}
        id={'smokingAcceptable'}
        value={'Y'}
        checked={props.data.smoking === 'Y'}
        handleChange={(e) => props.updateInput('propertyPreferences', e.target)}
        labelText={'Smoking'}
      />
      <RadioButton
        name={'smoking'}
        id={'nonSmokingShare'}
        value={'N'}
        checked={props.data.smoking === 'N'}
        handleChange={(e) => props.updateInput('propertyPreferences', e.target)}
        labelText={'Non-smoking'}
      />
    </div>
    <hr className="search-filter__separator" />
    <div className="search-filter__radio-wrapper">
      <CheckboxButton
        onChange={(e) => props.updateInput('propertyPreferences', e.target)}
        name={'pets_req'}
        id={'petsConsidered'}
        value={'Y'}
        check={props.data.petsConsidered}
        labelText={'Pets considered'}
      />
      {props.lookingForARoom ? (
        <>
          <CheckboxButton
            onChange={(e) => props.updateInput('propertyPreferences', e.target)}
            name={'living_room'}
            id={'sharedLivingRoom'}
            value={'shared'}
            check={props.data.sharedLivingRoom}
            labelText={'Shared living room'}
          />
          <CheckboxButton
            onChange={(e) => props.updateInput('propertyPreferences', e.target)}
            name={'parking'}
            id={'parkingAvailable'}
            value={'Y'}
            check={props.data.parkingAvailable}
            labelText={'Parking available'}
          />
          <CheckboxButton
            onChange={(e) => props.updateInput('propertyPreferences', e.target)}
            name={'disabled_access'}
            id={'disabledAccess'}
            value={'Y'}
            check={props.data.disabledAccess}
            labelText={'Disabled access'}
          />
        </>
      ) : null}
    </div>
  </section>
);

export default PropertyPreferences;
