import React from 'react';
import CheckboxButton from '../../inputs/checkbox';

const PriceIncludes = props => (
  <div>
    <CheckboxButton
      onChange={e => props.toggle('rent', e.target)}
      name={'bills_inc'}
      id={'billsIncluded'}
      value={'Yes'}
      check={props.billsCheck}
      labelText={
        props.country == 'US'
          ? 'Utilities included in the rent'
          : 'Bills included in the rent'
      }
    />
    {props.country == 'US' ? (
      <CheckboxButton
        onChange={e => props.toggle('rent', e.target)}
        name={'fees_apply'}
        id={'feesApply'}
        value={'N'}
        check={props.feesCheck}
        labelText={'No broker fee only'}
      />
    ) : null}
  </div>
);

export default PriceIncludes;
