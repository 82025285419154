import React from 'react';
import CheckboxButton from '../inputs/checkbox';

const HouseholdOptions = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="household-options-title"
  >
    <div id="household-options-title" className="form-group__title">
      <span>Household options</span>
    </div>
    <CheckboxButton
      onChange={(e) => props.updateInput('householdOptions', e.target)}
      name={'gayshare'}
      id={'lgbtShare'}
      value={'Y'}
      check={props.data.lgbtShare}
      labelText={'LGBT Household'}
    />
    {props.lookingForARoom ? (
      <>
        <CheckboxButton
          onChange={(e) => props.updateInput('householdOptions', e.target)}
          name={'vegetarians'}
          id={'vegetarians'}
          value={'Y'}
          check={props.data.vegetarians}
          labelText={'Vegetarian/vegan preferred'}
        />
      </>
    ) : null}
  </section>
);

export default HouseholdOptions;
