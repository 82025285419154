import React from 'react';
import RadioButton from '../inputs/radio';

const Couples = (props) => (
  <section
    className="search-filter__section"
    role="group"
    aria-labelledby="couples-title"
  >
    <div id="couples-title" className="form-group__title">
      <span>Couples?</span>
    </div>
    <div className="search-filter__radio-wrapper">
      <RadioButton
        name={'couples'}
        id={'anyCouple'}
        value={''}
        checked={props.data.couples === ''}
        handleChange={(e) => props.updateInput('couples', e.target)}
        labelText={"Don't mind"}
      />
      <RadioButton
        name={'couples'}
        id={'noCouples'}
        value={'N'}
        checked={props.data.couples === 'N'}
        handleChange={(e) => props.updateInput('couples', e.target)}
        labelText={'No couples'}
      />
    </div>
  </section>
);

export default Couples;
