import '../../../sass/apps/reg_prompt/reg_prompt.scss';
import addParametersToURLS from '../../helpers/addParametersToURLs';
import { fetchData } from '../../helpers/base_data_fetcher';
import sdkReporting from '../../services/facebookLogin/sdkReporting';
import trackEvent from '../../helpers/gtm/eventPush';

const addTrackingParameterToRegLink = (logRetPath) => {
  const addParametersToLoginReturnPath = addParametersToURLS(logRetPath);
  const loginReturnPath = addParametersToLoginReturnPath({ reg_popup: 1 });
  const regLink = document.getElementById('reg-prompt-aff-register');
  const path = regLink.pathname;
  return (regLink.href = `${path}?loginfrom_url=${encodeURIComponent(
    loginReturnPath,
  )}`);
};

function createPopUp(views) {
  // replace with new facebook_connect in next iteration
  if (window.SR && !SR.facebookConnect.loaded()) {
    SR.facebookConnect.init();
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'ga.event',
      ga_event_action: 'reg_popup_show',
      ga_event_category: 'registration',
      ga_event_label: `blocker`,
    });
  }

  const popUpTrigger = document.getElementById('reg_popup_aff_overlay');
  const popUpTarget = document.getElementById('reg_popup_aff');
  const numberOfAds = document.querySelector('.numer-of-ads');
  if (numberOfAds && views) {
    numberOfAds.innerHTML = views;
  }
  popUpTarget.classList.add('popreg-gated--active');
  popUpTrigger.click({
    trigger: popUpTrigger,
    target: popUpTarget,
  });

  if (window.dataLayer) {
    addGAEvents();
  }
}

const addGAEvents = () => {
  // const eventObject = {
  //   event: 'ga.event',
  //   ga_event_category: 'registration',
  //   ga_event_label: 'experiment',
  // };

  // document.querySelectorAll('.button--facebook').forEach((elem) => {
  //   elem.onclick = () => {
  //     if (elem.id == 'fb_login-reg-prompt-exp') {
  //       sdkReporting(window.FB);
  //       eventObject.ga_event_action = 'reg_popup_facebook';
  //       dataLayer.push(eventObject);
  //     } else {
  //       dataLayer.push(eventObject);
  //     }
  //   };
  // });

  const regItems = [
    {
      id: '#fb_login-reg-prompt-aff',
      action: 'reg_popup_facebook',
    },
    {
      id: '#reg-prompt-aff-register',
      action: 'reg_popup_email',
    },
    {
      id: '#reg_login_aff',
      action: 'reg_popup_login',
    },
    {
      id: '#reg_close_aff',
      action: 'reg_popup_close',
    },
    {
      id: '#overlay-mask',
      action: 'reg_popup_close',
    },
    {
      id: '#browseNav',
      action: 'browse',
    },
  ];

  function trackingRegPrompt() {
    regItems.forEach((regItem) => {
      const regElement = document.querySelector(regItem.id);
      if (regElement) {
        regElement.addEventListener('click', () => {
          trackEvent({
            action: `${regItem.action}`,
            category: 'registration',
            label: 'blocker',
          });
        });
      }
    });
  }
  trackingRegPrompt();

  document.addEventListener('keydown', (event) => {
    if (event.keyCode === 27) {
      trackEvent({
        action: 'reg_popup_close',
        category: 'registration',
        label: 'blocker',
      });
    }
  });
};

const init = (views) => {
  fetchData('page.loginReturnPath')
    .then((response) => addTrackingParameterToRegLink(response))
    .catch((err) => console.info(err));
  return createPopUp(views);
};

export default init;
