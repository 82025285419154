import React from 'react';
import ReactDOM from 'react-dom';
import trackEvent from '../../helpers/gtm/eventPush';
import '../../../sass/experiments/offered_ad_promo_banner.scss';

function capitalizeFirstLetter(string) {
  const words = string.split(' ');
  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
}

const textPanelHeader = (roomLocation, country) => {
  const toOrFor = country == 'GB' ? 'to' : 'for';
  let textHeader = `Got a room ${toOrFor} rent?`;
  const roomLocationLength = roomLocation.length;
  if (roomLocationLength > 0 && roomLocationLength < 15) {
    textHeader = `Got a room ${toOrFor} rent in ${capitalizeFirstLetter(
      roomLocation,
    )}?`;
  }
  return textHeader;
};

const OfferedAdPromo = ({ country, listingWapLink, roomLocation }) => {
  if (country == 'US') {
    return (
      <a
        className="listing-offeredad__link"
        href={listingWapLink}
        onClick={() => trackBanner(listingWapLink, country)}
      >
        <div className="listing-offeredad">
          <p className="listing-offeredad__header">
            {textPanelHeader(roomLocation, country)}
          </p>
          <p className="listing-offeredad__text">
            Reach 300k+ renters every month
          </p>
          <p className="listing-offeredad__linklike">
            Post a FREE room ad now!
          </p>
        </div>
      </a>
    );
  }
  return (
    <a
      className="listing-offeredad__link"
      href={listingWapLink}
      onClick={() => trackBanner(listingWapLink, country)}
    >
      <div className="listing-offeredad">
        <p className="listing-offeredad__header">
          {textPanelHeader(roomLocation, country)}
        </p>
        <p className="listing-offeredad__text">
          <span className="listing-offeredad__text-first-line">
            SpareRoom is the UK’s busiest{' '}
          </span>
          flatshare site{' '}
          <span className="listing-offeredad__linklike">
            Post a FREE room ad now!
          </span>
        </p>
      </div>
    </a>
  );
};

function trackBanner(listingWapLink, country) {
  const designVersion = country == 'GB' ? '1h' : '1i';

  trackEvent({
    category: 'banners',
    action: 'srp_promo_panel_click',
    label: `design=${designVersion}|posn=3|rnd=0|current=${window._sr.page.name}|target=${listingWapLink}`,
  });
}

function trackBannerView(country) {
  const designVersion = country == 'GB' ? '1h' : '1i';
  trackEvent({
    category: 'banners',
    action: 'srp_promo_panel_view',
    label: `design=${designVersion}|posn=3|rnd=0|current=${window._sr.page.name}`,
    non_interaction: true,
  });
}

const offeredAdPromoBanner = ({ mount_element, country }) => {
  const { link, location } = mount_element.dataset;
  const roomLocation = location;
  ReactDOM.render(
    <OfferedAdPromo
      country={country}
      listingWapLink={link}
      roomLocation={roomLocation}
    />,
    mount_element,
  );
  trackBannerView(country);
};

export default offeredAdPromoBanner;
