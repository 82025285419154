import React from 'react';
import RadioButton from '../../inputs/radio';

const PricePer = (props) => (
  <div role="group" aria-labelledby="price-per-title">
    <div id="price-per-title" className="sr-only">
      Rent per week or month
    </div>
    <RadioButton
      name={'per'}
      id={'week'}
      value={'pw'}
      checked={props.rentPeriod == 'pw'}
      handleChange={(e) => props.updateRentPeriod('rent', e.target)}
      labelText={'weekly'}
    />
    <RadioButton
      name={'per'}
      id={'month'}
      handleChange={(e) => props.updateRentPeriod('rent', e.target)}
      value={'pcm'}
      checked={props.rentPeriod == 'pcm'}
      labelText={'monthly'}
    />
  </div>
);

export default PricePer;
